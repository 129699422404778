import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';
import { ToastService } from './general/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UserNotesService {

  constructor(
    private backend: BackendService,
    private toast: ToastService,
    private translateService: TranslateService
  ) {}

  /**
   * Recupera la lista delle note dell'utente autenticato.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public getUserNotesList(
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.get('user-notes').subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Recupera i dettagli di una specifica nota.
   * @param id ID della nota.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public getUserNoteDetails(
    id: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.get(`user-notes/${id}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Crea una nuova nota.
   * @param noteData I dati della nota (inclusi titolo e testo).
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public createUserNote(
    noteData: any,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.post('user-notes', noteData).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Aggiorna una nota esistente.
   * @param id ID della nota da aggiornare.
   * @param noteData I nuovi dati della nota (titolo e testo).
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public updateUserNote(
    id: string,
    noteData: any,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.put(`user-notes/${id}`, noteData).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Elimina (soft delete) una nota.
   * @param id ID della nota da eliminare.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public deleteUserNote(
    id: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.destroy(`user-notes/${id}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }
}
