import { Injectable } from '@angular/core';
import { BackendService } from './general/backend.service';
import { ToastService } from './general/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ProjectPropertiesService {

  constructor(
    private backend: BackendService,
    private toast: ToastService,
    private translateService: TranslateService
  ) {}

  /**
   * Recupera la lista delle proprietà per una determinata organizzazione.
   * @param organizationId ID dell'organizzazione.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public getProjectPropertiesList(
    organizationId: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.get(`project-properties/list/${organizationId}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Recupera i dettagli di una specifica proprietà.
   * @param id ID della proprietà.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public getProjectPropertyDetails(
    id: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.get(`project-properties/details/${id}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Crea una nuova proprietà per una determinata organizzazione.
   * @param organizationId ID dell'organizzazione.
   * @param model Dati della proprietà da creare.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public createProjectProperty(
    organizationId: string,
    model: any,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.post(`project-properties/create/${organizationId}`, model).subscribe(
      result => {
        this.toast.success(
          this.translateService.instant('pages.home.component.check-decision-table'),
          this.translateService.instant('pages.projectProperties.created-successfully')
        );
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Aggiorna una proprietà esistente.
   * @param id ID della proprietà.
   * @param model Dati aggiornati della proprietà.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public updateProjectProperty(
    id: string,
    model: any,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.put(`project-properties/update/${id}`, model).subscribe(
      result => {
        this.toast.success(
          this.translateService.instant('pages.home.component.check-decision-table'),
          this.translateService.instant('pages.projectProperties.updated-successfully')
        );
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Elimina una proprietà esistente.
   * @param id ID della proprietà.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public deleteProjectProperty(
    id: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.destroy(`project-properties/delete/${id}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  // --- Metodi per Project Property Options ---

  /**
   * Recupera la lista delle opzioni per una determinata proprietà.
   * @param propertyId ID della proprietà.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public getProjectPropertyOptionsList(
    propertyId: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.get(`project-property-options/list/${propertyId}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Recupera i dettagli di una specifica opzione.
   * @param id ID dell'opzione.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public getProjectPropertyOptionDetails(
    id: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.get(`project-property-options/details/${id}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Crea una nuova opzione per una determinata proprietà.
   * @param propertyId ID della proprietà.
   * @param model Dati dell'opzione da creare.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public createProjectPropertyOption(
    propertyId: string,
    model: any,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.post(`project-property-options/create/${propertyId}`, model).subscribe(
      result => {
        this.toast.success(
          this.translateService.instant('pages.home.component.check-decision-table'),
          this.translateService.instant('pages.projectProperties.optionCreatedSuccessfully')
        );
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Aggiorna una opzione esistente.
   * @param id ID dell'opzione.
   * @param model Dati aggiornati dell'opzione.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public updateProjectPropertyOption(
    id: string,
    model: any,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.put(`project-property-options/update/${id}`, model).subscribe(
      result => {
        this.toast.success(
          this.translateService.instant('pages.home.component.check-decision-table'),
          this.translateService.instant('pages.projectProperties.optionUpdatedSuccessfully')
        );
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }

  /**
   * Elimina un'opzione esistente.
   * @param id ID dell'opzione.
   * @param successCallback Callback invocata in caso di successo.
   * @param errorCallback Callback invocata in caso di errore.
   */
  public deleteProjectPropertyOption(
    id: string,
    successCallback: (data: any) => void = (data: any) => {},
    errorCallback: (error: any) => void = (error: any) => {}
  ): void {
    this.backend.destroy(`project-property-options/delete/${id}`).subscribe(
      result => {
        successCallback(result);
      },
      error => {
        console.error(error);
        this.backend.showErrors(error);
        errorCallback(error);
      }
    );
  }
}
