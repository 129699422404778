<div class="container-fluid accordion-container" *ngFor="let section of sections; let i = index">
  <div [classList]="[['PROPOSALS', 'INVITE_PAGES'].includes(section.section) ? 'container-fluid px-0' : 'container']">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel [expanded]="section.expanded">
        <mat-expansion-panel-header class="container px-0">
          <mat-panel-title>
            <span class="index">{{ i + 1 }}</span>
            {{ section.title | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-container *ngTemplateOutlet="section.template"></ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<!-- Buttons -->
<div class="buttons-container container py-4">
  <button
    class="button-primary float-left"
    mat-button
    [disabled]="loading || true"
  >
    <i class="icon-Backward-arrow-small"></i>
    {{ "generic.buttons.save-and-back" | translate }}
  </button>
  <button
    class="button-primary float-right"
    mat-button
    [disabled]="loading || true"
  >
    <i class="icon-Forward-arrow-small"></i>
    {{ "generic.buttons.save-and-close" | translate }}
  </button>
</div>

<ng-template #dialogTemplate>
  <div class="container">
    <div class="row">
      <div class="col-12" style="font-size: 25px">
        {{
          "components.decision-table.show-decision-table.bot-proposal"
            | translate
        }}
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-12">
        {{
          "components.decision-table.show-decision-table.ask-bot-create-proposal"
            | translate
        }}
      </div>
    </div>
    <br/>
    <div class="row">
      <div class="col-6">
        <button
          color="primary"
          mat-raised-button
          (click)="confirmGenerateProposal()"
        >
          {{ "generic.answers.yes" | translate }}
        </button>
      </div>
      <div class="col-6">
        <button mat-raised-button (click)="dialog.closeAll()">
          {{
            "components.decision-table.show-decision-table.ask-bot-info"
              | translate
          }}
        </button>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #timelineTemplate>
  <div class="row">
    <div class="stepper-container col-12">
      <p class="headline float-left">
        {{
          "components.decision-table.show-decision-table.evaluation-sessions"
            | translate
        }}
      </p>
      <p class="headline float-right">
        {{ "generic.report" | translate }}
      </p>
      <div class="timeline">
        <div
          class="step-container"
          [ngClass]="session.collecting == true ? 'current' : ''"
          *ngFor="let session of evaluation_sessions_row_1; let i = index"
        >
          <ng-container>
            <div class="step">{{ i + 1 }}</div>
            <div class="step-label" *ngIf="session.evaluation_start">
              {{ session.evaluation_start | formatdate | date : "short" }}
              <br/>
              <span *ngIf="!session.collecting">{{
                  session.evaluation_end | formatdate | date : "short"
                }}</span>
            </div>
            <div class="step-label" *ngIf="!session.evaluation_start">
              {{
                "components.decision-table.show-decision-table.not-started-yet"
                  | translate
              }}
            </div>
          </ng-container>
        </div>
        <div
          class="step-container icon-container"
          *ngIf="
                  evaluation_sessions_row_1.length < evaluation_sessions_by_row
                "
        >
          <div class="step">
            <i class="icon-Flag"></i>
          </div>
        </div>
        <!-- <div class="step-container current">
                          <div class="step">2</div>
                          <div class="step-label">17 Luglio 2020</div>
                      </div> -->
      </div>
    </div>
    <div
      class="stepper-container col-12"
      *ngIf="
              evaluation_sessions_row_1.length >= evaluation_sessions_by_row
            "
    >
      <div class="timeline until-end">
        <div
          class="step-container"
          [ngClass]="session.collecting == true ? 'current' : ''"
          *ngFor="let session of evaluation_sessions_row_2; let i = index"
        >
          <ng-container>
            <div class="step">
              {{ evaluation_sessions_by_row + i + 1 }}
            </div>
            <div class="step-label">{{ session.decision_start }}</div>
          </ng-container>
        </div>
        <div class="step-container icon-container">
          <div class="step">
            <i class="icon-Flag"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row col-12 d-flex mb-3">

    <button *ngIf="isConsulted != null" class="mr-3 mb-1 pink"
            [routerLink]="['/responses/'+isConsulted?.evaluation_session_id+'/welcome']"
            routerLinkActive="router-link-active" mat-flat-button
            color="primary">{{ 'components.responses.ask-for-response' | translate }}
    </button>

    <!-- Aggiungo un flex per mandare i pulsanti dall'altro lato-->
    <div class="mx-auto"></div>

    <ng-container *ngIf="decision_table.closing_date">
      <div
        class="detailed-button mr-3 mb-1"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="onClickReopenDecisionTable()"
        >
          {{
            "components.decision-table.show-decision-table.reopen-project"
              | translate
          }}
        </button>
        <i class="icon-lock-open"></i>
      </div>
    </ng-container>

    <!-- Chiude la sessione se c'è ne una attiva -->
    <ng-container *ngIf="!decision_table.closing_date">
      <div class="detailed-button mr-3 mb-1" *ngIf="evaluationSessionActive && !isInformed">
        <button mat-flat-button color="primary" (click)="onClickCloseEvaluationSession()"
                [disabled]="!evaluationSessionActive || loading">{{ 'components.decision-table.show-decision-table.close-session' | translate }}
        </button>
        <i class="icon-lock-open"></i>
        <label>{{ modelStartNewEvaluation.closing_date | formatdate | date:'mediumDate' }}</label>
      </div>

      <!-- Chiude il tavolo decisionale se non ci sono sessioni attive -->
      <div
        class="detailed-button mr-3 mb-1"
        *ngIf="sessionsEvalutated && !evaluationSessionActive"
      >
        <button
          mat-flat-button
          color="primary"
          (click)="onClickCloseDecisionTable()"
          [disabled]="!sessionsEvalutated || loading"
        >
          {{
            "components.decision-table.show-decision-table.closetable"
              | translate
          }}
        </button>
        <i class="icon-lock-open"></i>
      </div>
    </ng-container>

    <ng-container>
      <div class="mr-3 mb-1">
        <button
          mat-flat-button
          color="primary"
          [routerLink]="['/decision/' + id + '/task-management']"
          routerLinkActive="router-link-active"
          [disabled]="locked"
        >
          {{ "generic.task-management" | translate }}
        </button>
      </div>
    </ng-container>

    <button
      class="mb-1"
      mat-flat-button
      color="primary"
      [disabled]="numOfProposalEvaluated == 0"
      [routerLink]="['/decision/' + id + '/dashboard']"
      routerLinkActive="router-link-active"
    >
      {{ "generic.dashboard" | translate }}
    </button>

    <ng-container *ngIf="decision_table.closing_date != null">
      <div class="mr-3 mb-1">
        <button
          mat-button
          color="primary"
          (click)="onClickDownloadExcelData()"
        >
          <mat-icon aria-hidden="false">description</mat-icon>
          {{ "generic.export-data" | translate }}
        </button>
      </div>
    </ng-container>
  </div>
</ng-template>

<ng-template #detailsTemplate>
  <div class="container my-5">
    <app-decision-table-details [decision_table]="decision_table" [show_indexes]="false" [isResponse]="false"/>
  </div>
</ng-template>

<ng-template #contestsTemplate>
  <div class="row container pt-4 pb-2 mx-auto">
    <div class="col-12 p-0 m-0">
      <ng-container *ngIf="contestDataSource && contestDataSource?.data.length == 0">
        <div class="row">
          <div class="col-12 text-center mb-4 mt-2">
            <p>
              {{ 'components.decision-table.show-decision-table.no-contests' | translate }}</p>
          </div>
          <div class="col-12 d-flex justify-content-center my-5">
            <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewContest()">
              <mat-icon class="mr-2">play_circle_outlined</mat-icon>
              {{ 'generic.create-new-contest' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="contestDataSource && contestDataSource.data.length > 0 && !contestIsCollecting()">
        <div class="row justify-content-center mb-4">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewContest()">
              <mat-icon class="mr-2">play_circle_outlined</mat-icon>
              {{ 'generic.create-new-contest' | translate }}
            </button>
          </div>
        </div>
      </ng-container>
      <ng-container>
        <mat-table [dataSource]="contestDataSource" matSort
                   *ngIf="contestDataSource && contestDataSource?.data.length > 0"
                   class="table-primary">

          <ng-container matColumnDef="title">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.title' | translate }}</mat-header-cell>
            <div class="row flex-column">
              <mat-cell *matCellDef="let row"> {{ row.title }}</mat-cell>
            </div>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.opening-date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row"> {{ row.created_at | date:'shortDate' }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="closing_date">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.closing-date' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let row">
              <span *ngIf="row.closing_date">{{ row.closing_date | date:'shortDate' }}</span>
              <span *ngIf="!row.closing_date">{{ 'generic.collecting' | translate }}</span>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef
                             class="text-center">{{ 'generic.actions' | translate }}
            </mat-header-cell>
            <mat-cell *matCellDef="let row">
              <div class="row flex-column space-between">
                <button class="my-2" mat-button (click)="showContest(row)"
                        color="primary">
                  <small>{{ 'generic.buttons.go-to-contest' | translate }}</small>
                  <mat-icon>forward</mat-icon>
                </button>
                <button class="my-2" mat-button
                        *ngIf="!row.closing_date"
                        (click)="copyLink(row.link)"
                        color="primary">
                  <small>{{ 'generic.buttons.copy-link' | translate }}</small>
                </button>
                <button class="my-2" mat-button
                        *ngIf="!row.closing_date"
                        (click)="closeContest(row)"
                        color="warn">
                  <small>{{ 'generic.buttons.close-contest' | translate }}</small>
                </button>
              </div>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="contestDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: contestDisplayedColumns;"></mat-row>

        </mat-table>
        <mat-paginator *ngIf="decision_table?.contests?.length > 0"
                       #contestsPaginator [pageSizeOptions]="[5, 10, 25, 100]"
                       aria-label="Select page of users"></mat-paginator>
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template #invitePages>
  <div class="row container pt-4 pb-2 mx-auto">
    <div class="col-12 p-0 m-0">

      <div>

        <ng-container *ngIf="invitationPagesDataSource && invitationPagesDataSource?.data.length == 0">
          <div class="row">
            <div class="col-12 text-center mb-4 mt-2">
              <p>
                {{ 'components.decision-table.show-decision-table.no-invite-pages' | translate }}
              </p>
            </div>
            <div class="col-12 d-flex justify-content-center my-5">
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewInvitationPage()">
                <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                {{ 'components.decision-table.show-decision-table.add-invite-page' | translate }}
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="invitationPagesDataSource && invitationPagesDataSource.data.length > 0">
          <div class="row justify-content-center mb-4">
            <div class="col-12 d-flex justify-content-between"
                 *ngIf="!decision_table?.decision_table_invitation_page_id">
              <mat-card style="background-color: #caaf49; color: white;"
                        class="py-2 px-5 border-0 d-flex align-items-center">
                {{ 'generic.alert.no_active_module' | translate }}
              </mat-card>
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2 mr-2" (click)="openInvitationPagesSettings()">
                <mat-icon class="mr-2">settings</mat-icon>
                {{ 'generic.settings' | translate }}
              </button>
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewInvitationPage()">
                <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                {{ 'components.decision-table.show-decision-table.add-invite-page' | translate }}
              </button>
            </div>
            <div class="col-12 d-flex justify-content-end" *ngIf="decision_table?.decision_table_invitation_page_id">
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2 mr-2" (click)="openInvitationPagesSettings()">
                <mat-icon class="mr-2">settings</mat-icon>
                {{ 'generic.settings' | translate }}
              </button>
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewInvitationPage()">
                <mat-icon class="mr-2">play_circle_outlined</mat-icon>
                {{ 'components.decision-table.show-decision-table.add-invite-page' | translate }}
              </button>
            </div>
          </div>
        </ng-container>

        <!-- Tabella delle pagine di invito -->
        <ng-container *ngIf="invitationPagesDataSource && invitationPagesDataSource?.data.length > 0">
          <div class="row">
            <div class="col-3" *ngIf="decision_table?.decision_table_invitation_page_id">
              <app-qr-code
                [join_address]="invitationPageUrl"
                [organization_logo]="currentOrganization?.logo_link">
              </app-qr-code>
            </div>
            <div [classList]="[decision_table?.decision_table_invitation_page_id ? 'col-9' : 'col-12']">
              <mat-table [dataSource]="invitationPagesDataSource" matSort class="table-primary">
                <!-- Definizione colonne -->
                <ng-container matColumnDef="title">
                  <mat-header-cell *matHeaderCellDef> {{ 'generic.title' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{ row.title }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="created_at">
                  <mat-header-cell *matHeaderCellDef> {{ 'generic.creation-date' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let row"> {{ row.created_at | date:'shortDate' }}</mat-cell>
                </ng-container>

                <ng-container matColumnDef="status">
                  <mat-header-cell *matHeaderCellDef> {{ 'generic.status.status' | translate }}</mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <mat-chip class="p-2 rounded" selected color="primary"
                              *ngIf="row.id === decision_table?.decision_table_invitation_page_id">
                      {{ "generic.active" | translate }}
                    </mat-chip>
                    <mat-chip class="p-2 rounded"
                              *ngIf="row.id !== decision_table?.decision_table_invitation_page_id">
                      {{ 'generic.deactive' | translate }}
                    </mat-chip>
                  </mat-cell>
                </ng-container>

                <ng-container matColumnDef="actions">
                  <mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.actions' | translate }}
                  </mat-header-cell>
                  <mat-cell *matCellDef="let row">
                    <div class="row flex-column space-between">
                      <button class="mb-2" mat-button (click)="addNewInvitationPage(row.id)" color="primary">
                        <small>{{ 'components.decision-table.show-decision-table.go-to-invitation-page' | translate }}</small>
                        <mat-icon>forward</mat-icon>
                      </button>
                      <button class="my-2" mat-button
                              *ngIf="row.id === decision_table?.decision_table_invitation_page_id; else elseButton"
                              (click)="updateInvitationPageStatus(row.id, 'deactive')" color="warn">
                        <small>{{ 'generic.deactivate' | translate }}</small>
                      </button>
                      <ng-template #elseButton>
                        <button class="my-2" mat-button (click)="updateInvitationPageStatus(row.id, 'active')"
                                color="primary">
                          <small>{{ 'generic.activate' | translate }}</small>
                        </button>
                      </ng-template>
                    </div>
                  </mat-cell>
                </ng-container>
                <mat-header-row *matHeaderRowDef="invitationPagesDisplayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: invitationPagesDisplayedColumns;"></mat-row>
              </mat-table>
              <mat-paginator *ngIf="decision_table.invitation_pages?.length > 0" #invitationPagesPaginator
                             [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
            </div>
          </div>
        </ng-container>
      </div>


      <h3 class="mt-5 mb-2 font-weight-bold text-center" *ngIf="decision_table?.join_requests?.length > 0">
        {{
          "generic.join-requests"
            | translate
        }}
      </h3>

      <ng-container *ngIf="decision_table?.join_requests?.length > 0">
        <mat-table [dataSource]="decision_table.join_requests" matSort class="table-primary mt-4">
          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.name' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let request"> {{ request.name }} {{ request.surname }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.email' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let request"> {{ request.email }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="number_phone">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.phone' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let request">
              <span *ngIf="request.number_phone; else elseBlock">{{ request.number_phone }}</span>
              <ng-template #elseBlock>N/A</ng-template>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="status">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.status.status' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let request">
              <mat-chip class="p-2 rounded" selected
                        [color]="request.status === 'approved' ? 'primary' : (request.status === 'refused' ? 'warn' : 'default')">
                {{ ('generic.' + request.status) | translate }}
              </mat-chip>
            </mat-cell>
          </ng-container>


          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef class="text-center">{{ 'generic.actions' | translate }}</mat-header-cell>
            <mat-cell *matCellDef="let request">
              <div class="row flex-column space-between" *ngIf="request.status === 'pending'; else elseBlock">
                <button class="my-2" mat-button (click)="updateJoinRequestStatus(request.id, 'approved')"
                        color="primary">
                  <small>{{ 'generic.approve' | translate }}</small>
                </button>
                <button class="my-2" mat-button (click)="updateJoinRequestStatus(request.id, 'refused')" color="warn">
                  <small>{{ 'generic.refuse' | translate }}</small>
                </button>
              </div>
              <ng-template #elseBlock>
                N/A
              </ng-template>
            </mat-cell>
          </ng-container>
          <mat-header-row *matHeaderRowDef="joinRequestsDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: joinRequestsDisplayedColumns;"></mat-row>
          <mat-paginator *ngIf="decision_table.join_requests?.length > 0" #joinRequestsPaginator
                         [pageSizeOptions]="[5, 10, 25, 100]" aria-label="Select page of users"></mat-paginator>
        </mat-table>
      </ng-container>
    </div>
  </div>
</ng-template>


<ng-template #currentEvaluationSessionTemplate>
  <div class="row container pt-2 pb-2 mx-auto">
    <div class="col-12 p-0 m-0">
      <ng-container *ngIf="getActiveEvaluationSession()">
        <div class="row mb-4">
          <div class="col-12 d-flex justify-content-end">
            <button class="btn-custom border-0 d-flex align-items-center px-5 py-2 mr-3" (click)="extractTeamData()">
              <fa-icon class="mr-2" [icon]="faDownload"></fa-icon>
              <span>{{ "generic.export-team-data" | translate }}</span>
            </button>
            <button class="btn-custom border-0 d-flex align-items-center px-5 py-2"
                    (click)="toggleAddNewTeamMemberMode()"
                    [ngClass]="singleTeamMemberToAddActive ? 'bg-danger' : ''">
              <fa-icon class="mr-2" *ngIf="!singleTeamMemberToAddActive" [icon]="faPlusCircle"></fa-icon>
              <fa-icon class="mr-2" *ngIf="singleTeamMemberToAddActive" [icon]="faTimes"></fa-icon>
              <span
                *ngIf="!singleTeamMemberToAddActive">{{ 'components.decision-table.new-decision-table.compile-proposal.add-team-member' | translate }}</span>
              <span *ngIf="singleTeamMemberToAddActive">{{ 'generic.cancel' | translate }}</span>
            </button>
          </div>
          <div class="col-12" *ngIf="singleTeamMemberToAddActive">
            <div class="box px-0 box-two-row mb-0 mt-4 border-modify pb-2"
                 [ngClass]="team_member_in_evaluation_session ? 'bg-red' : ''">
              <ng-container>
                <div class="my-4">
                  <app-single-team-member [team_member]="singleTeamMemberToAdd" [removable]="false"/>
                </div>
                <div class="row mx-auto flex-row-reverse">
                  <button mat-flat-button color="primary" (click)="addNewTeamMemberToCurrentSessionEvaluation()"
                          class="mr-2"
                          [disabled]="(!singleTeamMemberIsValid || !singleTeamMemberToAddActive)">{{ 'generic.buttons.add' | translate }}
                  </button>
                </div>
              </ng-container>
            </div>
          </div>
          <div class="col-12" *ngIf="team_member_in_evaluation_session">
                  <span
                    class="d-flex alert-danger text-center px-5 py-2 mb-2 border-0">{{ 'components.decision-table.new-decision-table.compile-proposal.team-member-is-already-in-evaluation-session' | translate }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="currentTeamMembersDataSource && currentTeamMembersDataSource?.data.length == 0">
        <div class="row">
          <div class="col-12 text-center mb-4 mt-2">
            <p>
              {{ 'generic.no-proposals' | translate }}</p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="currentTeamMembersDataSource">
        <mat-table [dataSource]="currentTeamMembersDataSource" class="table-primary">

          <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.name' | translate }}</mat-header-cell>
            <div class="row flex-column">
              <mat-cell *matCellDef="let row"> {{ row.name }}</mat-cell>
            </div>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell *matHeaderCellDef> {{ 'generic.email' | translate }}</mat-header-cell>
            <div class="row flex-column">
              <mat-cell *matCellDef="let row"> {{ row.email }}</mat-cell>
            </div>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell *matHeaderCellDef> {{
                "generic.roles.process-role" | translate
              }}
            </mat-header-cell>
            <div class="row flex-column">
              <mat-cell *matCellDef="let row">
                {{
                  processRolesService.getProcessRolesValueByKey(
                    row?.process_role
                  )
                }}
              </mat-cell>
            </div>
          </ng-container>

          <ng-container matColumnDef="response_status">
            <mat-header-cell
              *matHeaderCellDef> {{ 'components.decision-table.decision-table-dashboard.answers-to-form' | translate }}
            </mat-header-cell>
            <div class="row flex-column">
              <mat-cell *matCellDef="let row">
                <mat-chip-list>
                  <ng-container *ngIf="processRolesService.isConstulted(row.process_role)">
                    <mat-chip selectable="false"
                              *ngIf="row.response_stamps && row.response_stamps.completed == true"
                              style="justify-content:center; background-color: #00bc1d;"
                              class="text-white">{{ 'generic.answers.yes' | translate }}
                    </mat-chip>
                    <mat-chip selectable="false"
                              *ngIf="row.response_stamps && row.response_stamps.completed == false"
                              style="justify-content:center; background-color: #bca900;"
                              class="text-white">{{ 'generic.answers.draft' | translate }}
                    </mat-chip>
                    <mat-chip selectable="false" *ngIf="!row.response_stamps"
                              style="justify-content:center; background-color: #ff0000;"
                              class="text-white">{{ 'generic.answers.no' | translate }}
                    </mat-chip>
                  </ng-container>
                  <ng-container *ngIf="!processRolesService.isConstulted(row.process_role)">
                    <mat-chip selectable="false"
                              style="justify-content:center; background-color: grey;"
                              class="text-white">N/A
                    </mat-chip>
                  </ng-container>
                </mat-chip-list>
              </mat-cell>
            </div>
          </ng-container>

          <ng-container matColumnDef="email_status">
            <mat-header-cell
              *matHeaderCellDef> {{ 'components.decision-table.decision-table-dashboard.email-status' | translate }}
            </mat-header-cell>
            <div class="row flex-column">
              <mat-cell *matCellDef="let row">
                <mat-chip-list>
                  <mat-chip (click)="showEmailResponses(row)" role="button" selectable="true"
                            style="justify-content:center;" [matTooltip]="emailStatusByTeamMember(row)"
                            [matTooltipPosition]="'below'"
                            [ngStyle]="{'background-color':emailColorByTeamMember(row)}"
                            class="text-white cursor">
                    <fa-icon [icon]="faEnvelope"></fa-icon>
                  </mat-chip>
                  <button (click)="resendInvitation(row)" color="primary" mat-icon-button
                          matTooltip="{{'components.decision-table.decision-table-dashboard.tooltip.resend-email' | translate}}"
                          [matTooltipPosition]="'below'">
                    <fa-icon [icon]="faBullhorn"></fa-icon>
                  </button>
                </mat-chip-list>
              </mat-cell>
            </div>
          </ng-container>

          <mat-header-row *matHeaderRowDef="teamMemberDisplayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: teamMemberDisplayedColumns;"></mat-row>

        </mat-table>
        <mat-paginator #currentTeamPaginator [pageSizeOptions]="[5, 10, 25, 100]"
                       aria-label="Select page of users"></mat-paginator>

      </ng-container>

      <!-- <div class="row justify-content-center my-5" *ngIf="lastEvaluationSession && lastEvaluationSession?.settings">
        <div
          class="container-fluid bg-white px-0 mx-n-24 w-100 border-bottom-grey container"
        >
          <div class="container py-4 px-4 px-md-0 mx-5">
            <div class="row my-3 px-4">
              <mat-label class="label-box ml-2 font-weight-bold">
                {{
                "components.decision-table.show-decision-table.round-settings"
                  | translate
                }}
                <i class="icon-circle_info grey-color"></i>
              </mat-label>
            </div>
            <div class="row my-4">
              <div
                class="toggle-container col-5 d-inline-block"
                appearance="fill"
              >
                <i class="icon-circle_info abs-tr"></i>
                <mat-label>{{
                  "components.decision-table.show-decision-table.ask-authorizations-to-modify-proposal"
                    | translate
                  }}</mat-label>
                <div>
          <span class="mr-2">{{
            "generic.answers.no" | translate
            }}</span>
                  <mat-slide-toggle
                    class="toggle"
                    disabled
                      [ngModel]="lastEvaluationSession?.settings?.review_enabled"
                  >{{ "generic.answers.yes" | translate }}</mat-slide-toggle
                  >
                </div>
                <i class="abs-tr icon-circle_info grey-color"></i>
              </div>
              <div
                class="toggle-container offset-1 col-5 d-inline-block"
                appearance="fill"
              >
                <i class="icon-circle_info abs-tr"></i>
                <mat-label
                >{{
                  "components.decision-table.show-decision-table.ask-authorization-add-proposals"
                    | translate
                  }}
                </mat-label>
                <div>
          <span class="mr-2">{{
            "generic.answers.no" | translate
            }}</span>
                  <mat-slide-toggle
                    class="toggle"
                    disabled
                    [ngModel]="lastEvaluationSession?.settings?.provide_new_proposal_enabled"
                  >{{ "generic.answers.yes" | translate }}</mat-slide-toggle
                  >
                </div>
                <i class="abs-tr icon-circle_info grey-color"></i>
              </div>
            </div>
            <div class="row my-4">
              <div class="toggle-container col-5 d-inline-block" appearance="fill">
                <i class="icon-circle_info abs-tr"></i>
                <mat-label>{{'components.decision-table.show-decision-table.ask-anonimity' | translate}}</mat-label>
                <div>
                  <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                  <mat-slide-toggle class="toggle"
                                    disabled
                                    [ngModel]="lastEvaluationSession?.settings.anonymous_survey_enabled">{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                </div>
                <i class="abs-tr icon-circle_info grey-color"></i>
              </div>
              <div
                class="toggle-container offset-1 col-5 d-inline-block"
                appearance="fill"
              >
                <i class="icon-circle_info abs-tr"></i>
                <mat-label
                >{{
                  "components.decision-table.show-decision-table.ask-can-abstain"
                    | translate
                  }}
                </mat-label>
                <div>
          <span class="mr-2">{{
            "generic.answers.no" | translate
            }}</span>
                  <mat-slide-toggle
                    class="toggle"
                    disabled
                    [ngModel]="lastEvaluationSession?.settings?.can_abstain"
                  >{{ "generic.answers.yes" | translate }}</mat-slide-toggle
                  >
                </div>
                <i class="abs-tr icon-circle_info grey-color"></i>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</ng-template>

<ng-template #proposalsTemplate>
  <!-- colored box -->
  <div class="row container pt-4 pb-2 mx-auto">
    <div
      class="col-12 box box-two-row border-0 rounded pb-0"
      [ngClass]="getClassSuggestion(proposal)"
      *ngFor="let proposal of proposals"
    >
      <ng-container>
        <div class="row mt-0 mb-1">
          <i
            class="icon-show abs-tr p-2 main-color"
            (click)="editProposal(proposal)"
          ></i>
          <!-- <i class="icon-edit abs-tr p-2 main-color" (click)="editProposal(proposal)"></i> -->
          <div class="col-1 icon-container">
            <!-- <mat-checkbox [checked]="proposal.evaluation_session" [disabled]="pFroposal.evaluation_session"></mat-checkbox> -->
            <mat-checkbox
              *ngIf="!proposal.evaluation_session && decision_table.closing_date === null"
              (change)="proposalCheckedOnChange(proposal)"
            ></mat-checkbox>
          </div>
          <div class="col-4 col-lg-2 info-element">
            <p class="title">
              <ng-container *ngIf="isAlexion; else elseBlock">{{ "generic.session" | translate }}</ng-container>
              <ng-template #elseBlock>{{ "generic.proposal" | translate }}</ng-template>
            </p>
            <p class="description">{{ proposal.title }}</p>
          </div>
          <div class="col-3 col-lg-2 info-element" *appHideIfOrganization="'alexion'">
            <p class="title">{{ "components.decision-table.show-decision-table.proposed-by" | translate }}</p>
            <div class="content-grid">
              <img
                class="avatar-img"
                src="assets/img/user-placeholder.jpg"
              />
              <div>
                <p class="description">{{ proposal?.proposed_by?.name }}</p>
                <p class="subline m-0 p-0">{{ proposal?.proposed_by?.email }}</p>
                <p
                  class="subline m-0 p-0">{{ getProcessRolesService().getProcessRolesValueByKey(proposal?.proposed_by?.process_role) }}</p>
              </div>
            </div>
          </div>
          <div class="col-4 col-lg-2 info-element" *appHideIfOrganization="'alexion'">
            <p class="title">
                    <span *ngIf="!proposal.is_contest_proposal">{{
                        "components.decision-table.show-decision-table.creation-session"
                          | translate
                      }}</span>
              <span *ngIf="proposal.is_contest_proposal">{{
                  "components.decision-table.show-decision-table.creation-contest"
                    | translate
                }}</span>

            </p>
            <div *ngIf="!proposal.is_contest_proposal">
              <p
                class="description"
                *ngIf="
                      proposal.creation_session > 0;
                      else creationSessionStart
                    "
              >
                {{ "generic.session" | translate }} {{ proposal.creation_session }}
              </p>
              <ng-template #creationSessionStart
              ><p class="description">
                {{
                  "components.decision-table.show-decision-table.table-opening"
                    | translate
                }}
              </p>
              </ng-template>
            </div>
            <p class="description" *ngIf="proposal.is_contest_proposal">
              {{ getContestProposalById(proposal.creation_session)?.title }}
            </p>
          </div>
          <div class="col-5 offset-1 col-lg-2 offset-lg-0 info-element">
            <p class="title">
              {{
                (isAlexion ? 'pages.task-management.component.visit-date' : "components.decision-table.show-decision-table.when-evaluated")
                  | translate
              }}
            </p>
            <p
              class="description"
              *ngIf="proposal.evaluation_session; else notEvaluatedBox"
            >
              <ng-container *ngIf="!isAlexion; else visitDateSpan">
                {{ "generic.session" | translate }}
                {{ proposal.evaluation_session }}
              </ng-container>
              <ng-template #visitDateSpan>
                {{ proposal?.visit_date ? (proposal.visit_date | date) : "N/A" }}
              </ng-template>
            </p>
            <ng-template #notEvaluatedBox
            ><p class="description">
              {{
                "components.decision-table.show-decision-table.not-evaluated-yet"
                  | translate
              }}
            </p></ng-template
            >
          </div>
          <div class="col-5 col-lg-3 info-element">
            <p class="title">
              {{
                "components.decision-table.show-decision-table.obtained-score"
                  | translate
              }}
            </p>
            <div class="d-flex">
              <mat-progress-bar
                *ngIf="!isAlexion || (isAlexion && proposal.risk_index !== 'NaN') || proposal?.result === 'INEVALUATION'"
                [value]="isAlexion ? proposal.risk_index : (proposal.score / proposal.score_total) * 100"
              ></mat-progress-bar>
              <ng-container *ngIf="!isAlexion; else scoreAlexion">
                <mat-label
                  *ngIf="
                        proposal.evaluation_session && proposal?.score_total
                      "
                >{{ proposal.score }}/{{
                    proposal.score_total
                  }}
                </mat-label
                >
              </ng-container>
              <ng-template #scoreAlexion>
                <mat-label *ngIf="proposal?.risk_index == 'NaN' && proposal?.result !== 'INEVALUATION'" style="font-size: 16px">{{ 'generic.calculating' | translate }}</mat-label>
                <mat-label *ngIf="proposal?.risk_index && proposal?.risk_index !== 'NaN'">{{ proposal.risk_index }}% </mat-label>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="footer" *appHideIfOrganization="'alexion'">
                <span
                  *ngIf="proposal.evaluation_session; else notEvaluatedSuggest"
                >{{ getTextForSuggestion(proposal) }}</span
                >
          <ng-template #notEvaluatedSuggest
          ><span>{{
              "components.decision-table.show-decision-table.not-evaluated-yet"
                | translate
            }}</span></ng-template
          >
          <i
            class="abs-br px-3 abs-center-y d-table"
            [ngClass]="getIconSuggestion(proposal)"
          ></i>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Scelta opzionale delle proposte two buttons -->
  <div *appHideIfOrganization="'alexion'">
    <div
      *ngIf="decision_table.closing_date === null"
      class="container-fluid bg-white px-0 mx-n-24 w-100 border-bottom-grey"
    >
      <div class="container py-4 px-0">
        <div class="row justify-content-around mt-4 mb-5">
          <button
            *ngIf="decision_table?.weights_is_setted && evaluationSessionActive"
            mat-flat-button
            color="primary"
            (click)="editSessionWeights(false)"
          >
            {{
              "components.decision-table.show-decision-table.view-weights"
                | translate
            }}
          </button>
          <button
            mat-flat-button
            color="primary"
            [disabled]="numOfProposalEvaluated == 0 || true"
            (click)="generateProposal()"
          >
            {{
              "components.decision-table.show-decision-table.create-proposal"
                | translate
            }}
            <label class="label-bottom">{{
                "components.decision-table.show-decision-table.info-create-proposal"
                  | translate
              }}</label>
          </button>
          <button
            mat-flat-button
            color="primary"
            (click)="newProposal()"
            [disabled]="locked || decision_table.closing_date"
          >
            {{
              "components.decision-table.new-decision-table.compile-proposal.add-proposal"
                | translate
            }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Avviare una nuova sessione di valutazione yes or no -->
  <div
    class="container-fluid bg-white px-0 mx-n-24 w-100 border-top-grey border-bottom-grey"
    *ngIf="!locked && decision_table.closing_date === null"
  >
    <div class="container py-4 px-5">
      <div
        class="row py-4 two-radio-box mt-0 bg-transparent border-bottom-0"
      >
        <div class="container px-0">
          <p class="title">
            {{
              "components.decision-table.show-decision-table.ask-start-new-session"
                | translate
            }}
          </p>
          <mat-radio-group
            class="col-12 mat-radio-box px-0"
            aria-label="Select an option"
            name="start_new_evaluation"
            [(ngModel)]="startNewEvaluationChoice"
          >
            <mat-grid-list cols="12" rowHeight="100px">
              <ng-container>
                <mat-grid-tile
                  colspan="6"
                  (click)="onClickStartNewSession(true)"
                >
                  <mat-radio-button [value]="true" class="col-12">{{
                      "generic.answers.yes" | translate
                    }}
                  </mat-radio-button>
                </mat-grid-tile>
                <mat-grid-tile
                  colspan="6"
                  (click)="onClickStartNewSession(false)"
                >
                  <mat-radio-button [value]="false" class="col-12">{{
                      "generic.answers.no" | translate
                    }}
                  </mat-radio-button>
                </mat-grid-tile>
              </ng-container>
            </mat-grid-list>
          </mat-radio-group>
        </div>
      </div>
      <div
        class="row justify-content-center"
        *ngIf="startNewEvaluationChoice && startNewEvaluation == false"
      >
        <button
          mat-flat-button
          [disabled]="loading"
          color="primary"
          (click)="onClickConfirmStartNewSession()"
        >
          {{ "generic.buttons.confirm" | translate }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="startNewEvaluation && !locked">
    <!-- two datepicker -->
    <div
      class="container-fluid bg-white px-0 mx-n-24 w-100 border-bottom-grey"
    >
      <div class="container py-4 px-0">
        <div class="row px-4">
          <mat-form-field
            class="col-6 col-lg-4 datepicker-custom-button mt-1"
            (click)="dp_startEvaluationDate.open()"
          >
            <mat-label>{{
                "components.decision-table.show-decision-table.start-session-date"
                  | translate
              }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="dp_startEvaluationDate"
              name="start_evaluation_date"
              class="color-default ng-trim-ignore"
              [(ngModel)]="modelStartNewEvaluation.evaluation_start"
              [matDatepickerFilter]="startEvaluationFilter"
              (dateChange)="startEvaluationDateOnChange()"
              disabled
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="dp_startEvaluationDate"
            >
              <mat-icon matDatepickerToggleIcon
              >keyboard_arrow_down
              </mat-icon
              >
            </mat-datepicker-toggle>
            <mat-datepicker
              #dp_startEvaluationDate
              disabled="false"
            ></mat-datepicker>
          </mat-form-field>
          <mat-form-field
            class="col-6 col-lg-4 datepicker-custom-button mt-1"
            (click)="dp_endEvaluationDate.open()"
          >
            <mat-label>{{
                "components.decision-table.show-decision-table.end-session-date"
                  | translate
              }}
            </mat-label>
            <input
              matInput
              [matDatepicker]="dp_endEvaluationDate"
              name="end_evaluation_date"
              class="color-default ng-trim-ignore"
              [(ngModel)]="modelStartNewEvaluation.evaluation_end"
              [matDatepickerFilter]="endEvaluationFilter"
              disabled
            />
            <mat-datepicker-toggle matSuffix [for]="dp_endEvaluationDate">
              <mat-icon matDatepickerToggleIcon
              >keyboard_arrow_down
              </mat-icon
              >
            </mat-datepicker-toggle>
            <mat-datepicker
              #dp_endEvaluationDate
              disabled="false"
            ></mat-datepicker>
          </mat-form-field>
        </div>
      </div>
    </div>

    <!-- settaggi round -->
    <div
      class="container-fluid bg-white px-0 mx-n-24 w-100 border-bottom-grey"
    >
      <div class="container py-4 px-4 px-md-0">
        <div class="row my-3 px-4">
          <mat-label class="label-box ml-2 font-weight-bold">
            {{
              "components.decision-table.show-decision-table.round-settings"
                | translate
            }}
            <i class="icon-circle_info grey-color"></i>
          </mat-label>
        </div>
        <div class="row my-4">
          <div
            class="toggle-container col-5 d-inline-block"
            appearance="fill"
          >
            <i class="icon-circle_info abs-tr"></i>
            <mat-label>{{
                "components.decision-table.show-decision-table.ask-authorizations-to-modify-proposal"
                  | translate
              }}
            </mat-label>
            <div>
                    <span class="mr-2">{{
                        "generic.answers.no" | translate
                      }}</span>
              <mat-slide-toggle
                class="toggle"
                [(ngModel)]="
                        modelStartNewEvaluation?.settings.review_enabled
                      "
              >{{ "generic.answers.yes" | translate }}
              </mat-slide-toggle
              >
            </div>
            <i class="abs-tr icon-circle_info grey-color"></i>
          </div>
          <div
            class="toggle-container offset-1 col-5 d-inline-block"
            appearance="fill"
          >
            <i class="icon-circle_info abs-tr"></i>
            <mat-label
            >{{
                "components.decision-table.show-decision-table.ask-authorization-add-proposals"
                  | translate
              }}
            </mat-label>
            <div>
                    <span class="mr-2">{{
                        "generic.answers.no" | translate
                      }}</span>
              <mat-slide-toggle
                class="toggle"
                [(ngModel)]="
                        modelStartNewEvaluation?.settings
                          .provide_new_proposal_enabled
                      "
              >{{ "generic.answers.yes" | translate }}
              </mat-slide-toggle
              >
            </div>
            <i class="abs-tr icon-circle_info grey-color"></i>
          </div>
        </div>
        <div class="row my-4">
          <div class="toggle-container col-5 d-inline-block" appearance="fill">
            <i class="icon-circle_info abs-tr"></i>
            <mat-label>{{ 'components.decision-table.show-decision-table.ask-anonimity' | translate }}</mat-label>
            <div>
              <span class="mr-2">{{ 'generic.answers.no' | translate }}</span>
              <mat-slide-toggle class="toggle"
                                [(ngModel)]="modelStartNewEvaluation?.settings.anonymous_survey_enabled">{{ 'generic.answers.yes' | translate }}
              </mat-slide-toggle>
            </div>
            <i class="abs-tr icon-circle_info grey-color"></i>
          </div>
          <div
            class="toggle-container offset-1 col-5 d-inline-block"
            appearance="fill"
          >
            <i class="icon-circle_info abs-tr"></i>
            <mat-label
            >{{
                "components.decision-table.show-decision-table.ask-can-abstain"
                  | translate
              }}
            </mat-label>
            <div>
                    <span class="mr-2">{{
                        "generic.answers.no" | translate
                      }}</span>
              <mat-slide-toggle
                class="toggle"
                [(ngModel)]="
                        modelStartNewEvaluation?.settings.can_abstain
                      "
              >{{ "generic.answers.yes" | translate }}
              </mat-slide-toggle
              >
            </div>
            <i class="abs-tr icon-circle_info grey-color"></i>
          </div>
        </div>

        <div class="row my-4">
          <div
            class="toggle-container col-5 d-inline-block"
            appearance="fill"
          >
            <i class="icon-circle_info abs-tr"></i>
            <mat-label>{{
                "components.decision-table.show-decision-table.ask-authorizations-to-weights"
                  | translate
              }}
            </mat-label>
            <div>
                    <span class="mr-2">{{
                        "generic.answers.no" | translate
                      }}</span>
              <mat-slide-toggle
                [disabled]="!update_weights_is_allow"
                class="toggle"
                [(ngModel)]="
                        modelStartNewEvaluation?.settings.weights_provided_users
                      "
              >{{ "generic.answers.yes" | translate }}
              </mat-slide-toggle
              >
            </div>
            <div class="red-subline">
              {{
                "components.decision-table.show-decision-table.ask-authorizations-to-weights-description"
                  | translate
              }}
              <br><br>
              <span *ngIf="!update_weights_is_allow">
                      {{
                  "components.decision-table.show-decision-table.ask-authorizations-to-weights-is-disabled"
                    | translate
                }}
                    </span>

            </div>
            <i class="abs-tr icon-circle_info grey-color"></i>
          </div>
          <div *ngIf="templateEmail?.length > 0" class="toggle-container col-5 d-inline-block"
               [ngClass]="{'offset-1': update_weights_is_allow}" appearance="fill">
            <mat-label>{{ 'components.decision-table.select-template' | translate }}</mat-label>
            <mat-select name="email_template" [(ngModel)]="modelStartNewEvaluation?.settings.template_email_id"
                        required>
              <mat-option></mat-option>
              <mat-option *ngFor="let template of templateEmail" [value]="template.id">{{ template.name }}
              </mat-option>
            </mat-select>
            <i class="abs-tr icon-circle_info grey-color"></i>
          </div>
        </div>

        <div class="row my-5 justify-content-center" *ngIf="update_weights_is_allow && !isConsensus">
          <button
            mat-flat-button
            color="primary"
            (click)="editSessionWeights()"
            [disabled]="locked || decision_table.closing_date"
          >
            {{
              "components.decision-table.show-decision-table.edit-weights"
                | translate
            }}
          </button>
        </div>

        <div class="row my-5 justify-content-center"
             *ngIf="this.decision_table?.weights_is_setted && !isConsensus">
          <button
            mat-flat-button
            color="primary"
            (click)="editSessionWeights(false)"
          >
            {{
              "components.decision-table.show-decision-table.view-weights"
                | translate
            }}
          </button>
        </div>
        <!-- <div class="row my-4">
            <div class="toggle-container col-5 d-inline-block" appearance="fill">
                <i class="icon-circle_info abs-tr"></i>
                <mat-label>{{'generic.confidence-degree' | translate}}</mat-label>
                <div>
                    <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                    <mat-slide-toggle class="toggle" [(ngModel)]="modelStartNewEvaluation?.settings.degree_of_confidence_enabled">{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                </div>
                <div class="red-subline">
                    {{'components.decision-table.show-decision-table.temp-info-confidence-degree' | translate}}
                </div>
                <i class="abs-tr icon-circle_info grey-color"></i>
            </div>
            <div class="toggle-container offset-1 col-5 d-inline-block" appearance="fill">
                <i class="icon-circle_info abs-tr"></i>
                <mat-label>{{'components.decision-table.show-decision-table.ask-authorization-feedback' | translate}}</mat-label>
                <div>
                    <span class="mr-2">{{'generic.answers.no' | translate}}</span>
                    <mat-slide-toggle class="toggle" [(ngModel)]="modelStartNewEvaluation?.settings.provide_feedback_enabled">{{'generic.answers.yes' | translate}}</mat-slide-toggle>
                </div>
                <div class="red-subline">
                  {{'components.decision-table.show-decision-table.temp-info-confidence-degree' | translate}}
                </div>
                <i class="abs-tr icon-circle_info grey-color"></i>
            </div>
        </div> -->
      </div>
    </div>

    <!-- modifica team -->
    <div class="container-fluid px-0 mx-n-24 w-100">
      <div class="container py-4 px-0">
        <div class="row my-3 px-4">
          <mat-label class="label-box ml-2 font-weight-bold">
            {{
              "components.decision-table.show-decision-table.change-team"
                | translate
            }}
            <i class="icon-circle_info grey-color"></i>
          </mat-label>
        </div>
        <div class="row px-4 px-md-0">
          <div class="col-12 px-0">
            <!-- Box User -->
            <div
              class="box px-0 box-two-row"
              *ngFor="
                      let team_member of modelStartNewEvaluation.team_members;
                      let i = index
                    "
            >
              <app-single-team-member [team_member]="team_member"
                                      (removeTeamMemberEvent)="removeTeamMember(team_member)"/>
            </div>
          </div>
          <button class="col-12 big-button my-4" mat-raised-button (click)="addNewTeamMember()">
            <i class="icon-Add"></i>
            {{ 'components.decision-table.new-decision-table.compile-proposal.add-team-member' | translate }}
          </button>
          <button class="col-12 big-button mb-4 mt-3 ml-auto" mat-raised-button (click)="openChartsModal()">
            <i class="icon-Add"></i>
            {{ 'components.decision-table.new-decision-table.compile-proposal.import-team-members-from-chart' | translate }}
          </button>
        </div>
        <div class="row justify-content-center">
          <button
            mat-flat-button
            color="primary"
            [disabled]="loading"
            (click)="onClickSaveAndStartSession()"
          >
            {{ "generic.buttons.save-and-start-session" | translate }}
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</ng-template>
