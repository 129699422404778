import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams, HttpResponse} from '@angular/common/http';
import {environment} from '../../../environments/environment';

import {ToastService} from './toast.service';

import {saveAs} from 'file-saver';

@Injectable()
export class BackendService {
  constructor(private http: HttpClient, private toast: ToastService) {
  }

  saveAccessToken(token: any) {
    localStorage.setItem('access_token', token);
  }

  setupAuthHttpHeaders(searchParams: HttpParams = new HttpParams()) {
    const headers = new HttpHeaders({'Content-Type': 'application/json'});
    // headers = headers.set("Authorization", "Bearer " + localStorage.getItem("access_token"));

    //  uso dei token per autenticare l'utente
    const options: any = {
      headers,
      responseType: 'json',
      observe: 'body',
      params: searchParams,
      withCredentials: true,
    };
    // let options: any = { headers: headers, responseType: 'json', observe: 'body', params: searchParams };

    return options;
  }

  setupAuthHttpBlobHeaders(searchParams: HttpParams = new HttpParams()) {
    let headers = new HttpHeaders();
    // headers = headers.set("Authorization", "Bearer " + localStorage.getItem("access_token"));

    //  uso dei token per autenticare l'utente
    let options: any = {
      headers: headers,
      observe: 'response',
      responseType: 'blob' as 'json',
      params: searchParams,
      withCredentials: true,
    };
    // let options: any = { headers: headers, responseType: 'json', observe: 'body', params: searchParams };

    return options;
  }

  get(resource: string, searchParams: HttpParams = new HttpParams()) {
    const options = this.setupAuthHttpHeaders(searchParams);

    if (resource.startsWith('http://') || resource.startsWith('https://')) {
      return this.http.get(resource, options);
    }

    return this.http.get(environment.apiUrl + resource, options);
  }

  // getBlob(resource: string, queryParams: Object, searchParams: HttpParams = new HttpParams()) {
  getBlob(resource: string, searchParams: HttpParams = new HttpParams()) {
    const options = this.setupAuthHttpBlobHeaders(searchParams);

    if (resource.startsWith('http://') || resource.startsWith('https://'))
      return this.http.get(resource, options);

    return this.http.get(environment.apiUrl + resource, options);
  }

  getBlobPdfReport(resource: string, searchParams: HttpParams = new HttpParams()) {
    const options = this.setupAuthHttpBlobHeaders(searchParams);

    if (resource.startsWith('http://') || resource.startsWith('https://'))
      return this.http.get(resource, options);

    return this.http.get(environment.webUrl + resource, options);
  }

  post(
    resource: string,
    body: any,
    searchParams: HttpParams = new HttpParams()
  ) {
    const options = this.setupAuthHttpHeaders(searchParams);

    if (resource.startsWith('http://') || resource.startsWith('https://')) {
      return this.http.post(resource, JSON.stringify(body), options);
    }

    return this.http.post(
      environment.apiUrl + resource,
      JSON.stringify(body),
      options
    );
  }

  delete(
    resource: string,
    body: any,
    searchParams: HttpParams = new HttpParams()
  ) {
    const options = this.setupAuthHttpHeaders(searchParams);

    if (resource.startsWith('http://') || resource.startsWith('https://')) {
      return this.http.post(resource, JSON.stringify(body), options);
    }

    return this.http.post(
      environment.apiUrl + resource,
      JSON.stringify(body),
      options
    );
  }

  destroy(resource: string) {
    const options = this.setupAuthHttpHeaders();

    if (resource.startsWith('http://') || resource.startsWith('https://')) {
      return this.http.delete(resource, options);
    }

    return this.http.delete(environment.apiUrl + resource, options);
  }

  put(resource: string, body: any) {
    const options = this.setupAuthHttpHeaders();

    if (resource.startsWith('http://') || resource.startsWith('https://')) {
      return this.http.put(resource, JSON.stringify(body), options);
    }

    return this.http.put(
      environment.apiUrl + resource,
      JSON.stringify(body),
      options
    );
  }

  download(resource: string, filename: string, success_callback = (data: any) => {
           },
           error_callback = (error: any) => {
           }) {
    let headers = new HttpHeaders({'Content-Type': 'application/json'});
    headers = headers.set(
      'Authorization',
      'Bearer ' + localStorage.getItem('Api-Token')
    );

    const options: any = {
      headers,
      responseType: 'blob',
      withCredentials: true,
    };

    this.http.get(environment.apiUrl + resource, options).subscribe(
      (response: any) => {
        const blob = new Blob([response], {type: response.type});
        saveAs(blob, filename);
        success_callback(response);
      },
      (error) => {
        this.showErrors(error);
        error_callback(error);
      }
    );
  }

  showErrors(error: any) {
    if (error == null || !error?.status) {
      this.toast.error(
        'Backend ERROR',
        'No error message, please contact the support.'
      );
      return;
    }

    const errors = this.getResponseErrors(error);

    // controllo se è un errore di validazione
    if (error?.status === 422 || error?.status === 409 || error?.status === 400) {
      if (errors?.length > 0) {
        errors.forEach((error) => {
          this.toast.error('ERROR', error);
        });
      } else {
        this.toast.error('ERROR');
      }
    } else {

      if (error?.status == 404) {
        if (error?.error?.message) {
          this.toast.error('Error', error?.error?.message);
        } else {
          this.toast.error(
            'Resource not found',
            'what you were looking for was not found'
          );
        }
      } else if (
        error?.status == 401 ||
        (error?.message && error?.message.indexOf('Unauthenticated')) > 0
      ) {
        this.toast.warn(
          'Unauthorized',
          'Session expired or there is a new update available, try to login again.'
        );
        // this.router.navigate(["/login"]);
      } else if (
        error?.status == 403 ||
        (error?.message && error?.message.indexOf('Banned')) > 0
      ) {
        if (errors?.length > 0) {
          errors.forEach((error) => {
            this.toast.warn('Unauthorized', error);
          });
        } else {
          this.toast.warn('Unauthorized', 'Unauthorized user.');
        }
      }
      //  token non valido
      else if (error?.status == 419) {
        this.toast.warn(
          'Unauthorized',
          'Invalid or Expired Token, try to login again.'
        );
      } else {
        console.error('Backend error', error);
        this.toast.error(
          'Error ' + error?.status + ' ' + error?.statusText,
          'backend error, check the console for debug information'
        );
      }
    }
  }

  getResponseErrors(error) {
    const errors = [];

    if (error?.error?.errors) {
      for (const key in error.error.errors) {
        errors.push(error.error.errors[key]);
      }
      return errors;
    }

    if (error.error && error.error.message) {
      errors.push(error.error.message);
    }

    return errors;
  }
}
