import {Injectable} from '@angular/core';
import {BackendService} from './general/backend.service';

@Injectable({
  providedIn: 'root',
})
export class DecisionService {
  constructor(private backend: BackendService) {
  }

  /**
   * Recupera un tavolo decisionale
   * @param id_decision_table
   * @param success_callback
   * @param error_callback
   */
  public get(
    id_decision_table: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get('decision/' + id_decision_table).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Recupera gli obiettivi dal tavolo decisionale
   * @param id_decision_table
   * @param success_callback
   * @param error_callback
   */
  public getTableTargets(
    id_decision_table: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`decision/${id_decision_table}/targets`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Aggiunge i nuovi pesi ai criteri e targets
   * @param id_evaluation_session
   * @param attachment_id
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public addTableTargetAndCriteriasWithWeights(
    id_decision_table: any,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .delete(
        `decision/${id_decision_table}/add-targets-and-criteria-with-weights`,
        model
      )
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Recupera gli obiettivi e i criteri dal tavolo decisionale con i pesi
   * @param id_decision_table
   * @param success_callback
   * @param error_callback
   */
  public getTableTargetAndCriteriasWithWeights(
    id_decision_table: any,
    evaluation_session_id: any, // Aggiunto parametro evaluation_session_id
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    const queryParams = evaluation_session_id
      ? `?evaluation_session_id=${evaluation_session_id}`
      : '';

    this.backend
      .get(
        `decision/${id_decision_table}/targets-and-criteria-with-weights${queryParams}`
      )
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Recupera i pesi attualmente in uso sul tavolo
   * @param id_decision_table
   * @param success_callback
   * @param error_callback
   */
  public getLastWeightsUpdateByTableId(
    id_decision_table: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(`decision/${id_decision_table}/last-weights-update`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Recupera le informazioni dall'algoritmo che genera i risultati se presenti
   * @param decision_table_id
   * @param evaluation_session_id
   * @param success_callback
   * @param error_callback
   */
  public getDecisionTableResult(
    decision_table_id: string,
    evaluation_session_id: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(
        `decision/${decision_table_id}/results-session/${evaluation_session_id}`
      )
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Recupera le sessioni del tavolo di cui fa parte l'utente. La response contiene anche la lista dei ruoli dell'utente nelle sessioni.
   * @param decision_table_id
   * @param success_callback
   * @param error_callback
   */
  public getDecisionEvaluationSessionByUser(
    decision_table_id: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(`decision/${decision_table_id}/evaluation-sessions-by-user`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Recupera i contests del tavolo decisionale
   * @param decision_table_id
   * @param success_callback
   * @param error_callback
   */
  public getDecisionTableContests(
    decision_table_id: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`decision/${decision_table_id}/contests`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Salva un decision table e setta la prima proposta
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public createDecisionTable(
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.post('decision/create', model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Aggiunge una proposal nuova
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public addNewProposal(
    id_decision: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    let endpoint = `decision/${id_decision}/add-new-proposal`;
    if (model.isProposalContest) {
      endpoint = `decision/${id_decision}/contests/${model.contest_id}/add-new-proposal`;
    }
    this.backend.post(endpoint, model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public deleteProposal(
    id_decision: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/delete-proposal`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  public updateProposal(
    id_decision: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/update-proposal`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  public updateDecisionTable(
    id_decision: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/update`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Aggiunge una nuova proposal che è revisione di un'altra
   * @param id_decision
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public addNewRevision(
    id_decision: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/add-new-revision`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Restituisce una proposta con tutte le informazioni che la compongono
   * @param id_decision
   * @param id_proposal
   * @param success_callback
   * @param error_callback
   */
  public getProposalResponse(
    id_proposal: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`proposal/${id_proposal}/results`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Avvia una nuova sessione di decisione
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public startNewDecisionSession(
    id_decision: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/start-new-session`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Avvia una nuova sessione di decisione
   * @param id_decision
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public addNewTeamMemberToSessionEvaluation(
    id_decision: string,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/add-new-team-member`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Chiude la sessione di valutazione corrente per un tavolo decisionale
   * @param id_decision
   * @param success_callback
   * @param error_callback
   */
  public closeCurrentDecisionSession(
    id_decision: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`decision/${id_decision}/stop-current-session`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public closeContest(
    id_decision: string,
    id_contest: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/contests/${id_contest}/close`, null)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  public addNewContest(
    id_decision: string,
    contest: object,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.post(`decision/${id_decision}/contests`, contest).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public addNewInvitationPage(
    id_decision: string,
    invitation_page: object,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.post(`decision/${id_decision}/invitation-pages`, invitation_page).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public updateStatusInvitationPage(
    id_invitation_page: string,
    model: object,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.post(`invitation-pages/${id_invitation_page}/update-status`, model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public updateInvitationPage(
    invitation_page: object,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    // @ts-ignore
    this.backend.put(`invitation-pages/${invitation_page.id}`, invitation_page).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public updateDecisionInvitationSettings(
    decision_id: any,
    model: object,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    // @ts-ignore
    this.backend.put(`decision/${decision_id}/invitation-settings`, model).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public deleteInvitationPage(
    invitation_page: object,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.destroy(`invitation-pages/${invitation_page}`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public getInvitationPage(
    invitation_page: object,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`invitation-pages/${invitation_page}`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public getInvitationPageByDecisionTable(
    decision_id: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`decision/${decision_id}/invitation-page`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Chiude il tavolo decisionale corrente
   * @param id_decision
   * @param success_callback
   * @param error_callback
   */
  public closeCurrentDecisionTable(
    id_decision: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`decision/${id_decision}/close`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Riapre il tavolo decisionale corrente
   * @param id_decision
   * @param success_callback
   * @param error_callback
   */
  public reopenCurrentDecisionTable(
    id_decision: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`decision/${id_decision}/reopen`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Recupera le informazioni relative alla dashboard per una sessione di valutazione in particolare
   * @param decisiontable_id
   * @param success_callback
   * @param error_callback
   */
  public getDashboardForSession(
    evaluation_session_id: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(`evaluation_session/${evaluation_session_id}/dashboard`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Ricalcola i risultati della dashboard di una determinata sessione
   * @param evaluation_session_id
   * @param success_callback
   * @param error_callback
   */
  public calculateDashboardResults(
    evaluation_session_id: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`evaluation_session/${evaluation_session_id}/calculate-score-results`, undefined)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Ottieni il csv dei membri del team con le info relative alla sessione passata
   * @param evaluation_session_id
   * @param success_callback
   * @param error_callback
   */
  public extractTeamMembersCSV(evaluation_session_id: string, success_callback, error_callback) {
    this.backend.download(
      `evaluation_session/${evaluation_session_id}/extract-team-members-csv`,
      `Decision Session ${evaluation_session_id} Team Data`,
      success_callback,
      error_callback
    );
  }

  /**
   * Recupera le informazioni relative alla dashboard per una sessione di valutazione in particolare
   * @param decisiontable_id
   * @param success_callback
   * @param error_callback
   */
  public getAttachmentEvent(
    proposal_criteria_id: string,
    team_member_id: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(
        `attachment-event?proposal_criteria_id=${proposal_criteria_id}&team_member_id=${team_member_id}`
      )
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Rimuove un attachment passando il tipo l'id e il tipo
   * @param id_evaluation_session
   * @param attachment_id
   * @param model
   * @param success_callback
   * @param error_callback
   */
  public removeAttachment(
    id_evaluation_session,
    model: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .delete(`response/${id_evaluation_session}/delete-attachment`, model)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  // Salvo i nuovi dati della chiusura del tavolo decisionale

  /**
   * @param modelPlanned
   * @param success_callback
   * @param error_callback
   */
  public saveTargetAchived(
    id_decision: string,
    modelPlanned: any,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`decision/${id_decision}/target-achived`, modelPlanned)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Recupero il decision management plan dal tavolo
   * @param id_decision
   * @param success_callback
   * @param error_callback
   */
  public getManagementPlan(
    id_decision: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`decision/${id_decision}/decision-plan`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public getContest(
    contest_token: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`contests/${contest_token}`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  /**
   * Recupera i ruoli dell'utente corrente in base alla sessione passata
   * @param id_decision
   * @param success_callback
   * @param error_callback
   */
  public getDecisionTableSessionRolesForUser(
    id_decision: string,
    id_evaluation_session: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(`decision/${id_decision}/user-roles/${id_evaluation_session}`)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          // console.log(error);
          //this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * Scarico il file excel dei dati del tavolo decisionale
   * @param id_decision
   */
  public donwloadData(id_decision) {
    this.backend.download(
      `decision/${id_decision}/export-data`,
      'checklist_export'
    );
  }

  public getContestProposals(
    id_contest: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend.get(`contests/${id_contest}/proposals/`).subscribe(
      (result) => {
        success_callback(result);
      },
      (error) => {
        console.log(error);
        this.backend.showErrors(error);
        error_callback(error);
      }
    );
  }

  public acceptContestProposal(
    id_proposal: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .post(`proposal/${id_proposal}/accept-contest-proposal/`, undefined)
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  public resendInvitation(
    id_decision_table,
    id_team_member: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(
        `decision/${id_decision_table}/team-member/${id_team_member}/resend-invitation/`
      )
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  public getEmailResponsesForTeamMember(
    id_decision_table,
    id_team_member: string,
    success_callback = (data: any) => {
    },
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .get(
        `decision/${id_decision_table}/team-member/${id_team_member}/email-responses/`
      )
      .subscribe(
        (result) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }

  /**
   * stampa in pdf una proposal
   * @param proposal_id
   * @param success_callback
   * @param error_callback
   */
  public generatePdfReport(
    proposal_id: string,
    success_callback: (blob: Blob) => void,
    error_callback = (error: any) => {
    }
  ) {
    this.backend
      .getBlobPdfReport(`generate-pdf/proposal/${proposal_id}`)
      .subscribe(
        (result: any) => {
          success_callback(result);
        },
        (error) => {
          console.log(error);
          this.backend.showErrors(error);
          error_callback(error);
        }
      );
  }
}
