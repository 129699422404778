<!-- Spinner di caricamento -->
<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container-fluid accordion-container" *ngIf="!loading">
  <div class="container">
    <mat-accordion class="accordion" multi>
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <span class="index">1</span>
            {{ 'projectProperties.list.title' | translate }}
          </mat-panel-title>
        </mat-expansion-panel-header>

        <!-- Caso in cui non siano presenti properties -->
        <div *ngIf="!projectProperties || projectProperties.length === 0" class="container">
          <div class="row">
            <div class="col-12 text-center mb-4 mt-2">
              <p>{{ 'projectProperties.list.noProperties' | translate }}</p>
            </div>
            <div class="col-12 d-flex justify-content-center my-5">
              <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewProperty()">
                <mat-icon class="mr-2">add_circle</mat-icon>
                {{ 'projectProperties.list.newProperty' | translate }}
              </button>
            </div>
          </div>
        </div>

        <!-- Caso in cui siano presenti properties -->
        <ng-container *ngIf="projectProperties && projectProperties.length > 0">
          <div class="container px-0">
            <div class="row">
              <div class="col-12 d-flex justify-content-end align-items-center mb-4 mt-2">
                <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewProperty()">
                  <mat-icon class="mr-2">add_circle</mat-icon>
                  {{ 'projectProperties.list.newProperty' | translate }}
                </button>
              </div>
            </div>

            <!-- Campo per il filtraggio -->
            <div class="row mb-3">
              <div class="col-12">
                <mat-form-field appearance="standard" class="w-100">
                  <mat-label>{{ 'generic.searchByName' | translate }}</mat-label>
                  <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'generic.searchByNamePlaceholder' | translate }}">
                </mat-form-field>
              </div>
            </div>

            <!-- Tabella Angular Material -->
            <div class="mat-elevation-z8">
              <table mat-table [dataSource]="dataSource" matSort class="full-width-table">
                <!-- Colonna: Title -->
                <ng-container matColumnDef="title">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projectProperties.columns.title' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
                </ng-container>

                <!-- Colonna: Description -->
                <ng-container matColumnDef="description">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projectProperties.columns.description' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.description ? element.description : 'N/A' }} </td>
                </ng-container>

                <!-- Colonna: Options Count -->
                <ng-container matColumnDef="options_count">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'projectProperties.columns.options' | translate }} </th>
                  <td mat-cell *matCellDef="let element"> {{ element.options?.length || 0 }} </td>
                </ng-container>

                <!-- Colonna: Actions -->
                <ng-container matColumnDef="actions">
                  <th mat-header-cell *matHeaderCellDef> {{ 'generic.actions' | translate }} </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button color="primary" (click)="editProperty(element)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="deleteProperty(element)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </ng-container>


                <!-- Header e righe della tabella -->
                <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
              </table>
              <!-- Paginatore -->
              <mat-paginator [pageSizeOptions]="[5, 10, 20]" aria-label="Select page"></mat-paginator>
            </div>
          </div>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
  </div>
</div>

<!-- Bottone per tornare alla Home -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/home']" routerLinkActive="router-link-active">
    <mat-icon>arrow_back</mat-icon>
    {{ 'generic.buttons.home' | translate }}
  </button>
</div>
