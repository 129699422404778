<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container background-filters py-3 px-5 mt-3">
  <div class="row justify-content-between">
    <div class="purple-color">
      <h2>{{'generic.search-for' | translate}}</h2>
    </div>
    <div>
      <button class="clear-filter py-2 px-4" (click)="onClickResetFilters()">{{'generic.reset-filters' | translate}} <i class="icon-Close ml-2 size"></i></button>
    </div>
  </div>
  <div class="row d-flex justify-content-between align-items-center m-0">
    <div class="col-12 col-md-3">
      <mat-form-field class="datepicker-primary wp-normal color-default mx-2" appearance="fill" mx-2>
        <mat-label>{{ 'generic.start_date' | translate }}</mat-label>
        <input matInput class="ng-trim-ignore" (dateChange)="applyFilterToDataSource()" [placeholder]="'GG/MM/AAAA' | translate" [matDatepicker]="pickerStartDate" [(ngModel)]="searchModel.startDate">
        <mat-datepicker-toggle matSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerStartDate></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="col-12 col-md-3">
      <mat-form-field class="datepicker-primary wp-normal color-default" appearance="fill">
        <mat-label>{{ 'generic.end_date' | translate }}</mat-label>
        <input matInput class="ng-trim-ignore" (dateChange)="applyFilterToDataSource()" [placeholder]="'GG/MM/AAAA' | translate" [matDatepicker]="pickerEndDate" [(ngModel)]="searchModel.endDate">
        <mat-datepicker-toggle matSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerEndDate></mat-datepicker>
      </mat-form-field>
    </div>
        <div class="col-12 col-md-3">
          <mat-form-field class="select-primary wp-normal color-default" appearance="fill">
            <mat-label>{{ "generic.type" | translate }}</mat-label>
            <mat-select [formControl]="selectedTypologies" multiple (selectionChange)="applyFilterToDataSource()">
              <mat-option *ngFor="let type of typologiesList" [value]="type">{{type}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  </div>
  <div class="row d-flex justify-content-between mx-0 mt-2">
    <div class="col-12 col-md-12 mt-4">
      <mat-form-field class="full-width">
        <input matInput name="searchKeywords" [(ngModel)]="searchModel.name" type="text" placeholder="{{'pages.frameworks.all-frameworks.component.search-name' | translate}}" (keyup)="applyFilterToDataSource()">
        <mat-icon class="with-cursor" *ngIf="searchModel.name" matSuffix aria-label="Clear" (click)="searchModel.name=''; applyFilterToDataSource()">close</mat-icon>
        <mat-icon class="purple-color with-cursor" matSuffix aria-label="Clear" (click)="applyFilterToDataSource()">search</mat-icon>
      </mat-form-field>
    </div>
  </div>
</div>

<div class="my-5">
  <div *ngIf="surveys?.length == 0" class="container">
    <div class="row">
      <div class="col-12 text-center mb-4 mt-2">
        <p>{{'pages.projects.project-detail.no-surveys' | translate}}</p>
      </div>
      <div class="col-12 d-flex justify-content-center my-5" *ngIf="addable">
        <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewSurvey()">
          <mat-icon class="mr-2">play_circle_outlined</mat-icon>
          {{ 'routing.title.surveys' | translate}}
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="surveys?.length > 0">
    <div class="container px-0">
      <div class="row" *ngIf="addable">
        <div class="col-12 d-flex justify-content-end align-items-center mb-4 mt-2">
          <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewSurvey()">
            <mat-icon class="mr-2">play_circle_outlined</mat-icon>
            {{ 'routing.title.surveys' | translate}}
          </button>
        </div>
      </div>
      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" matSort>

          <ng-container matColumnDef="title">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.name' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
          </ng-container>

          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'pages.decision-management-plan.component.opening-date' | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'shortDate'}} </td>
          </ng-container>

          <ng-container matColumnDef="created_by_user">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "generic.created-by" | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ getCreatorFullNameBySurvey(element) }} </td>
          </ng-container>

          <ng-container matColumnDef="visibility_type">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ "generic.type" | translate }} </th>
            <td mat-cell *matCellDef="let element"> {{ capitalizeFirstLetter(element.visibility_type) }} </td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'generic.action' | translate }} </th>
            <td mat-cell *matCellDef="let element">
              <button mat-button color="primary" class="ml-2" *ngIf="surveyViewable"
                      [routerLink]="['/surveys/' + element.id]" routerLinkActive="router-link-active">
                <small>{{'generic.buttons.go-to-survey' | translate}}</small>
                <mat-icon>forward</mat-icon>
              </button>
              <button mat-button color="primary" *ngIf="surveySelectable" routerLinkActive="router-link-active" (click)="surveySelected.emit(element)">
                <small>{{'generic.select' | translate}}</small>
                <mat-icon>forward</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
          <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20]"
                       #paginator
                       aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </div>
  </ng-container>
</div>
