<header class="container-fluid" [ngClass]="headerHomeClass">
  <div class="container">
    <mat-toolbar>
      <mat-toolbar-row>
        <img
          [routerLink]="['/home']"
          routerLinkActive="router-link-active"
          class="logo"
          src="assets/img/butterfly-logo.png"
        />
        <span class="divider"></span>
        <img
          [routerLink]="['/home']"
          class="logo"
          src="assets/img/decision-maker-logo.png"
        />
        <span class="divider" *ngIf="getUserService().currentOrganization?.logo_link"></span>
        <img
            class="logo"
            [src]="getUserService()?.currentOrganization?.logo_link"
          />
        <div
          *ngIf="getUserService().userLoggedIn"
          class="ml-auto cursor"
          (click)="menuTrigger.openMenu()"
        >
          <span *ngIf="getUserService().userDetails?.first_name"
            >{{ "components.header.component.hello" | translate }}
            {{ getUserService().userDetails?.first_name }}
            {{ getUserService().userDetails?.last_name }}</span
          >
          <span *ngIf="!getUserService().userDetails?.first_name"
            >{{ "components.header.component.hello" | translate }}
            {{ getUserService().userInfo?.email }}</span
          >
          <button
            mat-icon-button
            class="ml-auto"
            #menuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="menu"
          >
            <img
              *ngIf="getUserService().userDetails.avatar_link"
              class="avatar-img-pic ml-2"
              [src]="getUserService()?.userDetails?.avatar_link"
            />
            <i
              *ngIf="!getUserService().userDetails.avatar_link"
              class="icon-Avatar"
            ></i>
          </button>
          <div *ngIf="getUserService().userLoggedIn" class="row ml-auto">
            <span
              class="organization-name"
              *ngIf="getUserService().currentOrganization"
              >{{ "generic.organization" | translate }}:
              {{ getUserService().currentOrganization?.name }}
              <mat-icon class="organization-name" style="height:21px;">keyboard_arrow_down</mat-icon>
            </span>
          </div>
          <!-- <div *ngIf="getUserService().userLoggedIn" class="row ml-auto">
            <img
              *ngIf="getUserService().currentOrganization?.logo_link"
              class="organization-logo"
              [src]="getUserService()?.currentOrganization?.logo_link"
            />
          </div> -->
          <mat-menu #menu="matMenu">
            <button mat-menu-item [routerLink]="['/profile']">
              <div class="row">
                <div class="col-2 d-flex align-items-center">
                  <img
                    *ngIf="getUserService().userDetails.avatar_link"
                    class="avatar-img-pic-menu"
                    [src]="getUserService().userDetails?.avatar_link"
                  />
                  <mat-icon *ngIf="!getUserService().userDetails.avatar_link"
                    ><i class="icon-Avatar"></i
                  ></mat-icon>
                </div>
                <div class="col-9 d-flex justify-content-start">
                  <span>{{
                    "components.header.component.show-profile" | translate
                  }}</span>
                </div>
              </div>
            </button>
            <button mat-menu-item [routerLink]="['/organization/all']">
              <div class="row">
                <div class="col-2 ">
                  <mat-icon>corporate_fare</mat-icon>
                </div>
                <div
                  class="col-9 d-flex justify-content-start align-items-center"
                >
                  <span>{{
                    "components.header.component.change-organization"
                      | translate
                  }}</span>
                </div>
              </div>
            </button>
            <button mat-menu-item [routerLink]="['/user-notes']" *ngIf="isButterflyTeam">
              <div class="row">
                <div class="col-2">
                  <mat-icon>notes</mat-icon>
                </div>
                <div
                  class="col-9 d-flex justify-content-start align-items-center"
                >
                  <span>{{
                      "components.header.component.user-notes"
                        | translate
                    }}</span>
                </div>
              </div>
            </button>
            <button
              type="submit"
              *ngIf="currentLang == 'it'"
              mat-menu-item
              (click)="useLanguage('en')"
            >
              <div class="row">
                <div class="col-2 d-flex align-items-center">
                  <i class="icon-flag-en"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span><span class="path4"></span
                    ><span class="path5"></span><span class="path6"></span
                    ><span class="path7"></span><span class="path8"></span
                    ><span class="path9"></span><span class="path10"></span
                    ><span class="path11"></span><span class="path12"></span
                  ></i>
                </div>
                <div
                  class="col-9 d-flex justify-content-start align-items-center"
                >
                  <span>{{
                    "components.header.component.change-language" | translate
                  }}</span>
                </div>
              </div>
            </button>
            <button
              type="submit"
              *ngIf="currentLang == 'en'"
              mat-menu-item
              (click)="useLanguage('it')"
            >
              <i class="icon-flag-it"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span
              ></i>
              &nbsp;
              <span>{{
                "components.header.component.change-language" | translate
              }}</span>
            </button>
            <button mat-menu-item class="logout" (click)="logout()">
              <mat-icon>logout</mat-icon>
              <span>{{ "generic.buttons.logout" | translate }}</span>
            </button>
          </mat-menu>
        </div>
      </mat-toolbar-row>
      <mat-toolbar-row class="d-flex justify-content-between">
        <h1>{{ pageTitle | translate }}</h1>
        <!-- <button *ngIf="!isHomeRoute() && !isRegisterRoute() && !isBootstrapRoute() && !isLoginRoute()" class="btn-homepage" [routerLink]="['/home']"><i class="icon-Backward-arrow-small"></i> Torna alla homepage</button> -->
      </mat-toolbar-row>
    </mat-toolbar>
  </div>
</header>
