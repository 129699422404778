import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { UserNotesService } from '../../../services/user-notes.service';
import { AlertService } from '../../../services/general/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../services/general/toast.service';
import {UserNoteDialogComponent, UserNoteDialogData} from '../../../modals/user-note-dialog/user-note-dialog.component';
import {HeaderTitleService} from "../../../services/general/header-title.service";

@Component({
  selector: 'app-user-notes-list',
  templateUrl: './user-notes-list.component.html',
  styleUrls: ['./user-notes-list.component.scss']
})
export class UserNotesListComponent implements OnInit, AfterViewInit {

  // Array che conterrà le note
  userNotes: any[] = [];
  // DataSource per la MatTable
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  // Colonne da visualizzare nella tabella
  columnsToDisplay: string[] = ['title', 'created_at', 'actions'];
  loading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userNotesService: UserNotesService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService,
    private toast: ToastService,
    private headerTitleService: HeaderTitleService
  ) {}

  ngOnInit(): void {
    this.headerTitleService.setTitle(this.translateService.instant('userNotes.list.title'));
    this.fetchUserNotes();
  }

  /**
   * Dopo che la vista è stata inizializzata, assegna paginator e sort al dataSource.
   */
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Recupera la lista delle note utente tramite il service e aggiorna il dataSource.
   */
  fetchUserNotes(): void {
    this.loading = true;
    this.userNotesService.getUserNotesList(
      (data: any) => {
        this.userNotes = data;
        this.dataSource = new MatTableDataSource(this.userNotes);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      (error: any) => {
        console.error('Errore nel caricamento delle note', error);
        this.loading = false;
      }
    );
  }

  /**
   * Applica un filtro ai dati della tabella.
   */
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  /**
   * Apre la dialog per la creazione di una nuova nota.
   * Se l'utente conferma, aggiunge la nuova nota alla lista e aggiorna il dataSource.
   */
  addNewNote(): void {
    const dialogRef = this.dialog.open(UserNoteDialogComponent, {
      panelClass: 'medium-dialog',
      data: {} as UserNoteDialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.userNotes.push(result);
        this.dataSource.data = [...this.userNotes];
      }
    });
  }

  viewNote(note: any): void {
    const dialogRef = this.dialog.open(UserNoteDialogComponent, {
      panelClass: 'medium-dialog',
      data: { note: note, read_mode: true }
    });
    dialogRef.afterClosed().subscribe(() => {
      // eventuale logica dopo la chiusura
    });
  }

  /**
   * Apre la dialog in modalità edit per modificare una nota.
   * Al termine, ricarica la lista delle note.
   */
  editNote(note: any): void {
    const dialogRef = this.dialog.open(UserNoteDialogComponent, {
      panelClass: 'fullscreen-dialog',
      data: { note } as UserNoteDialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.fetchUserNotes();
    });
  }

  /**
   * Elimina la nota dopo la conferma dell'utente.
   */
  deleteNote(note: any): void {
    this.alertService.showQuestionOnScreen(
      'warning',
      this.translateService.instant('notes.confirmDeletionTitle'),
      this.translateService.instant('notes.confirmDeletionText'),
      () => { // Callback in caso di conferma
        this.userNotesService.deleteUserNote(
          note.id,
          () => {
            this.toast.success(
              this.translateService.instant('notes.deletionSuccessTitle'),
              this.translateService.instant('notes.deletionSuccessText')
            );
            this.fetchUserNotes();
          },
          (error: any) => {
            this.toast.error(
              this.translateService.instant('notes.deletionErrorTitle'),
              this.translateService.instant('notes.deletionErrorText')
            );
            console.error('Errore durante l\'eliminazione della nota:', error);
          }
        );
      },
      () => {
        // Callback in caso di annullamento, se necessario
      }
    );
  }
}
