<mat-toolbar color="primary">
  <mat-toolbar-row class="container">
    <span>{{ 'pages.decision-tables.all-decision-table.component.new-decision-table' | translate}}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="!loading ? closeDialogue() : ''">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container my-5">
  <mat-card class="login-card">
    <mat-card-content>
      <form (ngSubmit)="onSubmit()">
        <mat-form-field class="full-width">
          <mat-label>{{ "generic.title" | translate }}</mat-label>
          <input matInput [(ngModel)]="dataModel.title" name="title" required>
        </mat-form-field>

        <mat-form-field class="full-width">
          <mat-label>{{ "generic.description" | translate }}</mat-label>
          <textarea matInput [(ngModel)]="dataModel.description" name="description"></textarea>
        </mat-form-field>

        <!-- Sezione per le proprietà con select -->
        <div *ngFor="let property of projectProperties" class="full-width">
          <mat-form-field class="full-width">
            <mat-label>{{ property.title }}</mat-label>
            <mat-select [(ngModel)]="dataModel.propertyOptions[property.id]" name="property_{{property.id}}">
              <mat-option *ngFor="let option of property.options" [value]="option.id">
                {{ option.title }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="row justify-content-center my-3">
          <a [disabled]="loading" mat-button color="warn" (click)="!loading ? closeDialogue() : ''" class="mr-2">{{"generic.buttons.cancel-and-back" | translate}}</a>
          <button [disabled]="loading" mat-raised-button color="primary" type="submit">{{"generic.send" | translate}}</button>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</div>
