import { Component, OnInit, AfterViewInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UserNotesService } from '../../services/user-notes.service';
import Quill from 'quill';

export interface UserNoteDialogData {
  note?: any;       // Se presente, siamo in modalità edit o read mode
  read_mode?: boolean; // Se true, la nota viene visualizzata in sola lettura
}

@Component({
  selector: 'app-user-note-dialog',
  templateUrl: './user-note-dialog.component.html',
  styleUrls: ['./user-note-dialog.component.scss']
})
export class UserNoteDialogComponent implements OnInit, AfterViewInit {
  noteForm: FormGroup;
  loading = false;
  isEditMode = false;
  readMode = false;
  dialogTitle: string;
  quillEditor: Quill;

  constructor(
    private fb: FormBuilder,
    private userNotesService: UserNotesService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<UserNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserNoteDialogData
  ) {}

  ngOnInit(): void {
    this.readMode = this.data.read_mode === true;
    // Se è presente una nota e non siamo in read mode, siamo in edit mode.
    this.isEditMode = !!this.data.note && !this.readMode;

    if (this.isEditMode) {
      // Modalità edit: il form viene popolato con i dati esistenti
      this.noteForm = this.fb.group({
        title: [this.data.note.title, Validators.required],
        text: [this.data.note.text, Validators.required]
      });
      this.dialogTitle = this.translateService.instant('userNotes.editNote');
    } else if (this.readMode) {
      // Modalità read: il form viene popolato con i dati esistenti, ma disabilitato
      this.noteForm = this.fb.group({
        title: [{ value: this.data.note.title, disabled: true }, Validators.required],
        text: [{ value: this.data.note.text, disabled: true }, Validators.required]
      });
      this.dialogTitle = this.noteForm.get('title').value;
    } else {
      // Modalità create: form vuoto per la creazione di una nuova nota
      this.noteForm = this.fb.group({
        title: ['', Validators.required],
        text: ['', Validators.required]
      });
      this.dialogTitle = this.translateService.instant('userNotes.newNote');
    }
  }

  ngAfterViewInit(): void {
    // Inizializza Quill sull'elemento con id "quillEditor"
    this.quillEditor = new Quill('#quillEditor', {
      theme: 'snow',
      readOnly: this.readMode,
      modules: {
        toolbar: this.readMode ? false : [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ header: 1 }, { header: 2 }],
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ direction: 'rtl' }],
          [{ size: ['small', false, 'large', 'huge'] }],
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          [{ color: [] }, { background: [] }],
          [{ font: [] }],
          [{ align: [] }],
          ['link'], // pulsante per inserire hyperlink
          ['clean']
        ]
      }
    });

    // Imposta il contenuto iniziale, se disponibile
    if (this.data.note && this.data.note.text) {
      this.quillEditor.root.innerHTML = this.data.note.text;
    }

    // Se non siamo in read mode, sincronizza il contenuto dell'editor con il form
    if (!this.readMode) {
      this.quillEditor.on('text-change', () => {
        const html = this.quillEditor.root.innerHTML;
        this.noteForm.get('text').setValue(html);
      });
      // Quando l'editor perde il focus, marca il controllo come touched
      this.quillEditor.on('selection-change', (range) => {
        if (range === null) {
          this.noteForm.get('text').markAsTouched();
        }
      });
    }
  }

  onSubmit(): void {
    // Se il form è invalido, marca tutti i controlli come touched e interrompi l'invio.
    if (this.noteForm.invalid) {
      this.noteForm.markAllAsTouched();
      return;
    }
    this.loading = true;
    if (this.isEditMode) {
      // Modalità edit: aggiorna la nota esistente
      this.userNotesService.updateUserNote(
        this.data.note.id,
        this.noteForm.value,
        (result: any) => {
          this.loading = false;
          this.dialogRef.close(result);
        },
        (error: any) => {
          this.loading = false;
          console.error('Errore durante l\'aggiornamento della nota:', error);
        }
      );
    } else if (!this.readMode) {
      // Modalità create: crea una nuova nota
      this.userNotesService.createUserNote(
        this.noteForm.value,
        (result: any) => {
          this.loading = false;
          this.dialogRef.close(result);
        },
        (error: any) => {
          this.loading = false;
          console.error('Errore durante la creazione della nota:', error);
        }
      );
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
