import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DecisionService} from '../../services/decision.service';
import {ModalAddContestComponent} from '../modal-add-contest/modal-add-contest.component';
import {ProjectsService} from '../../services/projects.service';
import {UserService} from '../../services/user.service';
import {MatTableDataSource} from '@angular/material/table';
import {ProjectPropertiesService} from '../../services/project-properties.service';

@Component({
  selector: 'app-modal-add-project',
  templateUrl: './modal-add-project.component.html',
  styleUrls: ['./modal-add-project.component.scss']
})
export class ModalAddProjectComponent implements OnInit{
  dataModel = {
    title: '',
    description: '',
    propertyOptions: {}
  };
  organization_id = null;
  loading = false;
  projectProperties: Array<object>;

  constructor(
    public dialogRef: MatDialogRef<ModalAddContestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {decision_table_id: null},
    private projectService: ProjectsService,
    private userService: UserService,
    private projectPropertiesService: ProjectPropertiesService
  ) {}

  ngOnInit() {
    this.organization_id = this.userService.getCurrentOrganization()?.id;
    if (!this.organization_id) {
      this.closeDialogue();
    }
    this.fetchProjectProperties(this.organization_id);
  }

  fetchProjectProperties(organizationId: string): void {
    this.loading = true;
    this.projectPropertiesService.getProjectPropertiesList(
      organizationId,
      (data: any) => {
        this.projectProperties = data;
        this.loading = false;
      },
      (error: any) => {
        this.loading = false;
      }
    );
  }

  onSubmit() {
    this.loading = true;
    this.projectService.createProject(this.organization_id, this.dataModel, (result) => {
      this.closeDialogue(result);
    }, () => {
      this.loading = false;
    });
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close(event);
  }
}
