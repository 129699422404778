<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ 'modals.modal-invitation-page-settings.title' | translate }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container">
  <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
    <mat-card class="w-75 my-5 ml-auto mr-auto">
      <mat-card-content>
        <div class="my-2">
          <mat-checkbox formControlName="invitationPageAutoApprovalEnabled" [disabled]="loading">
            {{ "modals.modal-invitation-page-settings.enable-auto-approval" | translate }}
          </mat-checkbox>
        </div>

        <div class="my-2" *ngIf="form.get('invitationPageAutoApprovalEnabled')?.value">
          <mat-form-field class="full-width mt-2">
            <mat-label>{{ "modals.modal-invitation-page-settings.user-type" | translate }}</mat-label>
            <mat-select formControlName="invitationPageDefaultUserType" [disabled]="loading" required>
              <mat-option *ngFor="let type of processRoles.getAllProcessRoles()" [value]="type?.key">{{ type.value }}</mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('invitationPageDefaultUserType')?.hasError('required')">
              {{ "modals.modal-invitation-page-settings.select-user-type" | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="row justify-content-center my-3 mt-5">
          <a mat-button color="warn" (click)="closeDialogue()" [disabled]="loading" class="mx-2">
            {{ "generic.buttons.cancel-and-back" | translate }}
          </a>
          <button mat-raised-button color="primary" class="mx-2" type="submit" [disabled]="loading || form.invalid">
            {{ "generic.buttons.update" | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </form>

</div>
