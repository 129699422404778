import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { result } from 'lodash';

import { filter, map, mergeMap } from 'rxjs/operators';
import { HeaderTitleService } from 'src/app/services/general/header-title.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private headerTitleService: HeaderTitleService,
    private userService: UserService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  headerHomeClass = '';
  pageTitle: string = '';
  currentLang = 'it';

  ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) route = route.firstChild;
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data)
      )
      .subscribe((event) => {
        this.headerTitleService.setTitle(event['title']);

        if (event['isHome']) {
          this.headerHomeClass = 'header-transparent';
          this.document.body.classList.add('home-body');
        } else if (event['isBootstrap']) {
          this.headerHomeClass = 'header-transparent ';
          this.document.body.classList.add('home-body');
        } else if (event['isOnBoarding']) {
          this.headerHomeClass = 'header-transparent';
          this.document.body.classList.add('home-body');
        } else {
          this.headerHomeClass = '';
          this.document.body.classList.remove('home-body');
        }
      });

    //  aggiorna il titolo della pagina
    this.headerTitleService.title.subscribe((updatedTitle) => {
      this.pageTitle = updatedTitle;
    });

    //  setto il linguaggio corrente
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang.substring(0, 2);
    });
  }

  // isHomeRoute(){
  //   return this.router.url === '/home';
  // }

  // isRegisterRoute(){
  //   return this.router.url === '/register';
  // }

  // isBootstrapRoute(){
  //   return this.router.url === '/';
  // }

  // isLoginRoute(){
  //   return this.router.url === '/login'
  // }

  login() {
    this.userService.loginUser();
    this.destroyCustomTokens();
  }

  logout() {
    this.userService.logoutUser();
    this.destroyCustomTokens();
  }

  public getUserService() {
    return this.userService;
  }

  useLanguage(language: string): void {

    this.userService.saveLanguage(language);
  }

  destroyCustomTokens() {
    localStorage.removeItem('order_number_alexion_logged');
  }

  get isButterflyTeam() {
    return ['butterflysrl.com', 'butterflydecisions.com']
      .some(char => this.userService?.userInfo?.email?.endsWith(char));
  }
}
