export const proposeRevision =
  {
    "role": "user",
    "organization_role":"medic",
    "content": "You are an acknowledged and honest expert {organization_role} and you want to propose some key suggestions based on the following knowledge:\n{context}\nAnalyze the following question:\n{question}\n\nAlso Consider the feedback provided by the experts that participated to an advisory board:\n{text}\n\nPropose a revision of the question taking into account this feedback. Clearly specify the suggested changes and the rationale behind each modification. Additionally, identify any points of disagreement and suggest how they could be resolved to achieve a broader consensus.\nDo not include the prompt in the answer and answer in five key points.",
    // "content": "You are an acknowledged and honest expert medic and you want to propose some key suggestions based on the following knowledge:\n{context}\nAnalyze the following question:\n{question}\n\nAlso Consider the feedback provided by the experts that participated to an advisory board:\n{text}\n\nPropose a revision of the question taking into account this feedback. Clearly specify the suggested changes and the rationale behind each modification. Additionally, identify any points of disagreement and suggest how they could be resolved to achieve a broader consensus.\nDo not inclute the prompt in the answer and answer in five key points.",
    "type":"proposeRevision",
  }

  export const rewriteStatement =
  {
    "role":"user",
    "organization_role":"medical writer",
    // "content":"Act as a honest and expert medical writer medic who has the following knowledge:\n{context}.\n Given the following expert feedbacks:\n{text}, try to rewrite the following statements using the consensus method:\n{question}\nAnswer following this template:\n|rewritten_statement:[REWRITTEN STATEMENT]|rationale:[RATIONALE]|.\n You are allowed to answer only if sure and you can post just a single answer.",
    "content":"Act as a honest and expert {organization_role} who has the following knowledge:\n{context}.\n Given the following expert feedbacks:\n{text}, try to rewrite the following statements using the consensus method:\n{question}\nAnswer following this template:\n|rewritten_statement:[REWRITTEN STATEMENT]|rationale:[RATIONALE]|.\n You are allowed to answer only if sure and you can post just a single answer.",
    "type":"rewriteStatement",
  }

export const rewriteStatementBeforeAnswers =
  {
    "role": "user",
    "organization_role":"medical writer",
    // "content": "Act as a honest and expert medical writer medic who has the following knowledge:\n{context}.\n Try to rewrite the following statements using the consensus method:\n{question}\nAnswer following this template:\n|rewritten_statement:[REWRITTEN STATEMENT]|rationale:[RATIONALE]|.\nYou are allowed to answer only if sure and you can post just a single answer.",
    "content": "Act as a honest and expert {organization_role} who has the following knowledge:\n{context}.\n Try to rewrite the following statements using the consensus method:\n{question}\nAnswer following this template:\n|rewritten_statement:[REWRITTEN STATEMENT]|rationale:[RATIONALE]|.\nYou are allowed to answer only if sure and you can post just a single answer.",
    "type":"rewriteStatementBeforeAnswers",
  }

