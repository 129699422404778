import {Component, EventEmitter, Inject, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ToastService} from "../../services/general/toast.service";
import {TranslateService} from "@ngx-translate/core";
import {DecisionService} from "../../services/decision.service";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ProcessRolesService} from "../../services/config/process-roles.service";

@Component({
  selector: 'app-modal-invitation-pages-settings',
  templateUrl: './modal-invitation-pages-settings.component.html',
  styleUrls: ['./modal-invitation-pages-settings.component.scss']
})
export class ModalInvitationPagesSettingsComponent {

  loading = false;
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<ModalInvitationPagesSettingsComponent>,
    private toast: ToastService,
    private translateService: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { decision_table: any },
    private decisionService: DecisionService,
    private fb: FormBuilder,
    public processRoles: ProcessRolesService
  ) {
    this.form = this.fb.group({
      invitationPageAutoApprovalEnabled: [false],
      invitationPageDefaultUserType: [null]
    });

    const customs = this.data?.decision_table?.customs;

    if (customs) {
      this.form.patchValue({
        invitationPageAutoApprovalEnabled: customs.invitationPageAutoApprovalEnabled ?? false,
        invitationPageDefaultUserType: customs.invitationPageDefaultUserType ?? null
      });
    }

    this.form.get('invitationPageAutoApprovalEnabled')?.valueChanges.subscribe(enabled => {
      const defaultUserTypeControl = this.form.get('invitationPageDefaultUserType');
      if (enabled) {
        defaultUserTypeControl?.setValidators(Validators.required);
      } else {
        defaultUserTypeControl?.clearValidators();
        defaultUserTypeControl?.setValue(null);
      }
      defaultUserTypeControl?.updateValueAndValidity();
    });

    const invitationDefaultRole = this.data?.decision_table?.customs?.invitation_default_role;
    if (invitationDefaultRole) {
      this.form.patchValue({
        invitationPageAutoApprovalEnabled: true,
        invitationPageDefaultUserType: invitationDefaultRole
      });
    }
  }

  closeDialogue(result: any = null) {
    this.dialogRef.close(result);
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const formData = this.form.value;

    this.loading = true;
    this.decisionService.updateDecisionInvitationSettings(this.data?.decision_table?.id, formData, (result) => {
      this.toast.success(
        this.translateService.instant('generic.success'),
        this.translateService.instant('generic.settings-updated-successfully')
      )
      this.closeDialogue(formData)
      this.loading = false;
    }, (error) => {
      this.loading = false;
    });
  }
}
