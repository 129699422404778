import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../../services/user.service';
import { ProjectPropertiesService } from '../../../services/project-properties.service';
import { AlertService } from '../../../services/general/alert.service';
import { PropertyCreateDialogComponent, PropertyDialogData } from '../../../modals/property-create-dialog/property-create-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ToastService } from '../../../services/general/toast.service';

@Component({
  selector: 'app-project-property-list',
  templateUrl: './project-property-list.component.html',
  styleUrls: ['./project-property-list.component.scss']
})
export class ProjectPropertyListComponent implements OnInit, AfterViewInit {

  currentOrganization: any = null;
  projectProperties: any[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>([]);
  columnsToDisplay: string[] = ['title', 'description', 'options_count', 'actions'];
  loading = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private userService: UserService,
    private projectPropertiesService: ProjectPropertiesService,
    private dialog: MatDialog,
    private alertService: AlertService,
    private translateService: TranslateService,
    private toast: ToastService,
  ) {}

  ngOnInit(): void {
    this.init();
  }

  /**
   * Dopo che la vista è stata inizializzata,
   * assegna paginator e sort al dataSource (se esistono).
   */
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  /**
   * Inizializza i dati prendendo l'organizzazione corrente
   * e caricando la lista delle proprietà.
   */
  init(): void {
    this.currentOrganization = this.userService.getCurrentOrganization();
    if (this.currentOrganization && this.currentOrganization.id) {
      this.fetchProjectProperties(this.currentOrganization.id);
    }
  }

  /**
   * Recupera la lista di proprietà dal service,
   * aggiorna l'array `projectProperties` e la `dataSource`.
   */
  fetchProjectProperties(organizationId: string): void {
    this.loading = true;
    this.projectPropertiesService.getProjectPropertiesList(
      organizationId,
      (data: any) => {
        this.projectProperties = data;
        this.dataSource = new MatTableDataSource(this.projectProperties);
        // Assegna nuovamente paginator e sort una volta caricati i dati
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loading = false;
      },
      (error: any) => {
        console.error('Errore nel caricamento delle proprietà', error);
        this.loading = false;
      }
    );
  }

  /**
   * Applica il filtro in base al testo inserito dall'utente
   * nella barra di ricerca.
   */
  applyFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    // Esempio di filter nativo di MatTableDataSource (case insensitive)
    if (this.dataSource) {
      this.dataSource.filter = filterValue.trim().toLowerCase();
      if (this.dataSource.paginator) {
        this.dataSource.paginator.firstPage();
      }
    }
  }

  /**
   * Apre la dialog di creazione di una nuova property.
   * Se l'utente salva, aggiunge la nuova property alla lista
   * e aggiorna il dataSource.
   */
  addNewProperty(): void {
    const dialogRef = this.dialog.open(PropertyCreateDialogComponent, {
      panelClass: 'medium-dialog',
      data: { organizationId: this.currentOrganization.id } as PropertyDialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.projectProperties.push(result);
        this.dataSource.data = [...this.projectProperties];
      }
    });
  }

  /**
   * Apre la dialog in modalità edit per modificare una property.
   * Se al termine viene restituito un risultato, ricarica i dati da backend.
   */
  editProperty(property: any): void {
    const dialogRef = this.dialog.open(PropertyCreateDialogComponent, {
      panelClass: 'fullscreen-dialog',
      data: {
        organizationId: this.currentOrganization.id,
        property
      } as PropertyDialogData
    });

    dialogRef.afterClosed().subscribe(result => {
      this.fetchProjectProperties(this.currentOrganization.id);
    });
  }

  /**
   * Elimina la property dopo conferma da parte dell'utente.
   */
  deleteProperty(property: any): void {
    this.alertService.showQuestionOnScreen(
      'warning',
      this.translateService.instant('projectProperties.confirmDeletionTitle'),
      this.translateService.instant('projectProperties.confirmDeletionText'),
      () => { // ok_callback
        this.projectPropertiesService.deleteProjectProperty(
          property.id,
          () => {
            this.toast.success(
              this.translateService.instant('projectProperties.deletionSuccessTitle'),
              this.translateService.instant('projectProperties.deletionSuccessText')
            );
            this.fetchProjectProperties(this.currentOrganization.id);
          },
          (error: any) => {
            this.toast.error(
              this.translateService.instant('projectProperties.deletionErrorTitle'),
              this.translateService.instant('projectProperties.deletionErrorText')
            );
            console.error('Errore durante l\'eliminazione della property:', error);
          }
        );
      },
      () => {
        // Callback di annullamento, se serve
      }
    );
  }
}
