<!-- Header del dialog -->
<mat-toolbar color="primary" class="dialog-header">
  <mat-toolbar-row>
    <span class="dialog-title">{{ dialogTitle }}</span>
    <span class="dialog-spacer"></span>
    <button mat-icon-button (click)="!loading ? closeDialog() : null">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<!-- Progress bar in caso di caricamento -->
<div class="dialog-loading" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div>

<div class="dialog-body container">
  <form [formGroup]="propertyForm" (ngSubmit)="onSubmit()">
    <!-- Modalità CREATE: una sola card con title, description e opzioni -->
    <ng-container *ngIf="!isEditMode">
      <mat-card class="dialog-card">
        <mat-card-content>
          <!-- Campo: Title -->
          <div class="form-group">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ 'projectProperties.form.title' | translate }}</mat-label>
              <input matInput formControlName="title" placeholder="{{ 'projectProperties.form.titlePlaceholder' | translate }}">
              <mat-error *ngIf="propertyForm.get('title').hasError('required')">
                {{ 'projectProperties.form.titleError' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Campo: Description -->
          <div class="form-group">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ 'projectProperties.form.description' | translate }}</mat-label>
              <textarea matInput formControlName="description" placeholder="{{ 'projectProperties.form.descriptionPlaceholder' | translate }}"></textarea>
            </mat-form-field>
          </div>
          <!-- Sezione: Options -->
          <div formArrayName="options" class="form-group">
            <label class="section-label mb-3">{{ 'projectProperties.form.options' | translate }}</label>
            <div *ngFor="let optionCtrl of options.controls; let i = index" [formGroupName]="i" class="option-row">
              <mat-form-field appearance="fill" class=" w-75">
                <mat-label>{{ 'projectProperties.form.optionTitle' | translate }} {{ i + 1 }}</mat-label>
                <input matInput formControlName="title" placeholder="{{ 'projectProperties.form.optionPlaceholder' | translate }}">
              </mat-form-field>
              <button mat-icon-button color="warn" type="button" class="mb-3" (click)="removeOptionCreateMode(i)">
                <mat-icon>delete</mat-icon>
              </button>
            </div>
            <div class="option-add">
              <button mat-button color="primary" type="button" (click)="addOptionCreateMode()">
                <mat-icon>add_circle</mat-icon>
                {{ 'projectProperties.form.addOption' | translate }}
              </button>
            </div>
          </div>
        </mat-card-content>
        <mat-card-actions align="end" class="dialog-actions">
          <button mat-button type="button" (click)="closeDialog()">
            {{ 'generic.cancel' | translate }}
          </button>
          <button mat-raised-button color="primary" type="submit" [disabled]="propertyForm.invalid || loading">
            {{ 'generic.buttons.save' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>
    </ng-container>

    <!-- Modalità EDIT: due card separate -->
    <ng-container *ngIf="isEditMode">
      <!-- Card per i dettagli della property (title e description) -->
      <mat-card class="dialog-card mb-4">
        <mat-card-title class="mb-4">{{ 'projectProperties.form.propertyDetails' | translate }}</mat-card-title>
        <mat-card-content>
          <!-- Campo: Title -->
          <div class="form-group">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ 'projectProperties.form.title' | translate }}</mat-label>
              <input matInput formControlName="title" placeholder="{{ 'projectProperties.form.titlePlaceholder' | translate }}">
              <mat-error *ngIf="propertyForm.get('title').hasError('required')">
                {{ 'projectProperties.form.titleError' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <!-- Campo: Description -->
          <div class="form-group">
            <mat-form-field appearance="fill" class="full-width">
              <mat-label>{{ 'projectProperties.form.description' | translate }}</mat-label>
              <textarea matInput formControlName="description" placeholder="{{ 'projectProperties.form.descriptionPlaceholder' | translate }}"></textarea>
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions align="end" class="dialog-actions mb-auto mt-auto">
          <button mat-button type="button" (click)="closeDialog()">
            {{ 'generic.cancel' | translate }}
          </button>
          <button mat-raised-button color="primary" type="button" (click)="updateProperty()" [disabled]="propertyForm.invalid || loading">
            {{ 'generic.buttons.save' | translate }}
          </button>
        </mat-card-actions>
      </mat-card>

      <!-- Card per la gestione delle opzioni -->
      <mat-card class="dialog-card">
        <mat-card-title class="mb-4">{{ 'projectProperties.form.options' | translate }}</mat-card-title>
        <mat-card-content>
          <div *ngFor="let optionForm of optionForms; let i = index" class="option-row mb-2">
            <mat-form-field appearance="fill" class="w-60 pb-0">
              <mat-label>{{ 'projectProperties.form.optionTitle' | translate }} {{ i + 1 }}</mat-label>
              <input matInput [disabled]="optionForms?.length <= 2" [formControl]="optionForm.get('title')" placeholder="{{ 'projectProperties.form.optionPlaceholder' | translate }}">
            </mat-form-field>
            <div class="option-actions mb-3 mr-auto ml-auto">
              <button mat-button color="primary" type="button" (click)="updateOption(optionForm)" [disabled]="optionForm.invalid || loading">
                {{ 'generic.buttons.save' | translate }}
              </button>
              <button mat-button *ngIf="optionForms?.length > 2" color="warn" type="button" (click)="deleteOption(optionForm, i)" [disabled]="loading">
                {{ 'generic.buttons.delete' | translate }}
              </button>
            </div>
          </div>
          <div class="option-row mb-2">
            <mat-form-field appearance="fill" class="w-60 pb-0">
              <mat-label>{{ 'projectProperties.form.newOptionTitle' | translate }}</mat-label>
              <input matInput [formControl]="newOptionForm.get('title')" placeholder="{{ 'projectProperties.form.optionPlaceholder' | translate }}">
            </mat-form-field>
            <button mat-button color="primary" type="button" class="mb-3 mr-auto ml-auto" (click)="addNewOptionEditMode()" [disabled]="newOptionForm.invalid || loading">
              <mat-icon>add_circle</mat-icon>
              {{ 'projectProperties.form.addOption' | translate }}
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </ng-container>
  </form>
</div>
