import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-modal-show-surveys',
  templateUrl: './modal-show-surveys.component.html',
  styleUrls: ['./modal-show-surveys.component.scss']
})
export class ModalShowSurveysComponent {
  constructor(
    public dialogRef: MatDialogRef<ModalShowSurveysComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      surveys: any,
      project_id: any,
    }) {
  }

  closeDialogue(event=null): void {
    this.dialogRef.close(event);
  }
}
