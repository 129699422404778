import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {ProjectPropertiesService} from "../../services/project-properties.service";

export interface PropertyDialogData {
  organizationId: string;
  property?: any; // Se presente, siamo in modalità edit
}

@Component({
  selector: 'app-property-create-dialog',
  templateUrl: './property-create-dialog.component.html',
  styleUrls: ['./property-create-dialog.component.scss']
})
export class PropertyCreateDialogComponent implements OnInit {
  propertyForm: FormGroup;
  loading = false;
  isEditMode = false;
  dialogTitle: string;
  // In edit mode, le opzioni vengono gestite separatamente
  optionForms: FormGroup[] = [];
  // Form per aggiungere una nuova opzione in edit mode
  newOptionForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private projectPropertiesService: ProjectPropertiesService,
    private translateService: TranslateService,
    public dialogRef: MatDialogRef<PropertyCreateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PropertyDialogData
  ) {}

  ngOnInit(): void {
    this.isEditMode = !!this.data.property;
    if (this.isEditMode) {
      // Modalità edit: il form principale contiene solo title e description
      this.propertyForm = this.fb.group({
        title: [this.data.property.title, Validators.required],
        description: [this.data.property.description]
      });
      this.dialogTitle = this.translateService.instant('projectProperties.editProperty');

      // Inizializza i form per le opzioni esistenti
      if (this.data.property.options && Array.isArray(this.data.property.options)) {
        this.optionForms = this.data.property.options.map(opt =>
          this.fb.group({
            id: [opt.id],
            title: [opt.title, Validators.required]
          })
        );
      }
      // Garantisce che siano presenti almeno 2 opzioni
      while (this.optionForms.length < 2) {
        this.optionForms.push(this.fb.group({
          id: [null],
          title: ['', Validators.required]
        }));
      }
      // Form per l'inserimento di una nuova opzione
      this.newOptionForm = this.fb.group({
        title: ['', Validators.required]
      });
    } else {
      // Modalità create: il form include title, description e un FormArray di opzioni
      this.propertyForm = this.fb.group({
        title: ['', Validators.required],
        description: [''],
        options: this.fb.array([
          this.fb.group({ title: ['', Validators.required] }),
          this.fb.group({ title: ['', Validators.required] })
        ])
      });
      this.dialogTitle = this.translateService.instant('projectProperties.list.newProperty');
    }
  }

  // Per la modalità create: getter per il FormArray delle opzioni
  get options(): FormArray {
    return this.propertyForm.get('options') as FormArray;
  }

  // Modalità create: aggiunge una nuova opzione al FormArray
  addOptionCreateMode(): void {
    this.options.push(this.fb.group({ title: ['', Validators.required] }));
  }

  // Modalità create: rimuove un'opzione dal FormArray se ce ne sono più di 2
  removeOptionCreateMode(index: number): void {
    if (this.options.length > 2) {
      this.options.removeAt(index);
    }
  }

  // Per la modalità edit: aggiorna la property (title e description)
  updateProperty(): void {
    if (this.propertyForm.valid) {
      this.loading = true;
      this.projectPropertiesService.updateProjectProperty(
        this.data.property.id,
        this.propertyForm.value,
        (result: any) => {
          this.loading = false;
          // Si chiude la modale restituendo il risultato aggiornato
          this.dialogRef.close(result);
        },
        (error: any) => {
          this.loading = false;
          console.error('Errore durante l\'aggiornamento della property:', error);
        }
      );
    }
  }

  // Modalità create: invia il form per creare la property (con opzioni)
  createProperty(): void {
    if (this.propertyForm.valid) {
      this.loading = true;
      this.projectPropertiesService.createProjectProperty(
        this.data.organizationId,
        this.propertyForm.value,
        (result: any) => {
          this.loading = false;
          this.dialogRef.close(result);
        },
        (error: any) => {
          this.loading = false;
          console.error('Errore durante la creazione della property:', error);
        }
      );
    }
  }

  onSubmit(): void {
    if (this.isEditMode) {
      this.updateProperty();
    } else {
      this.createProperty();
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  // Funzioni per la gestione individuale delle opzioni in modalità edit

  // Aggiorna un'opzione esistente usando la sua API dedicata
  updateOption(optionForm: FormGroup): void {
    if (optionForm.valid && optionForm.value.id) {
      this.projectPropertiesService.updateProjectPropertyOption(
        optionForm.value.id,
        { title: optionForm.value.title },
        (result: any) => {
          // Aggiornamento riuscito; eventualmente mostrare un messaggio
        },
        (error: any) => {
          console.error('Errore durante l\'aggiornamento dell\'opzione:', error);
        }
      );
    }
  }

  // Elimina un'opzione esistente usando la sua API dedicata
  deleteOption(optionForm: FormGroup, index: number): void {
    if (optionForm.value.id) {
      this.projectPropertiesService.deleteProjectPropertyOption(
        optionForm.value.id,
        (result: any) => {
          // Rimuove l'opzione dall'array locale dopo la cancellazione
          this.optionForms.splice(index, 1);
        },
        (error: any) => {
          console.error('Errore durante l\'eliminazione dell\'opzione:', error);
        }
      );
    }
  }

  // Aggiunge una nuova opzione in modalità edit tramite la sua API dedicata
  addNewOptionEditMode(): void {
    if (this.newOptionForm.valid) {
      this.projectPropertiesService.createProjectPropertyOption(
        this.data.property.id,
        this.newOptionForm.value,
        (result: any) => {
          // Aggiunge la nuova opzione all'array locale
          this.optionForms.push(this.fb.group({
            id: [result.id],
            title: [result.title, Validators.required]
          }));
          this.newOptionForm.reset();
        },
        (error: any) => {
          console.error('Errore durante la creazione della nuova opzione:', error);
        }
      );
    }
  }
}
