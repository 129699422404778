<!-- Spinner di caricamento -->
<div class="row" *ngIf="loading">
  <mat-progress-bar mode="indeterminate" color="primary"></mat-progress-bar>
</div>

<div class="container my-5">
    <!-- Caso in cui non siano presenti note -->
    <div *ngIf="!userNotes || userNotes.length === 0" class="container">
        <div class="row">
            <div class="col-12 text-center mb-4 mt-2">
                <p>{{ 'userNotes.list.noNotes' | translate }}</p>
            </div>
            <div class="col-12 d-flex justify-content-center my-5">
                <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewNote()">
                    <mat-icon class="mr-2">add_circle</mat-icon>
                    {{ 'userNotes.list.newNote' | translate }}
                </button>
            </div>
        </div>
    </div>

    <!-- Caso in cui siano presenti note -->
    <ng-container *ngIf="userNotes && userNotes.length > 0">
        <div class="container px-0">
            <div class="row">
                <div class="col-12 d-flex justify-content-end align-items-center mb-4 mt-2">
                    <button class="btn-custom border-0 d-flex align-items-center px-5 py-2" (click)="addNewNote()">
                        <mat-icon class="mr-2">add_circle</mat-icon>
                        {{ 'userNotes.list.newNote' | translate }}
                    </button>
                </div>
            </div>

            <!-- Campo per il filtraggio -->
            <div class="row mb-3">
                <div class="col-12">
                    <mat-form-field appearance="standard" class="w-100">
                        <mat-label>{{ 'generic.searchByName' | translate }}</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="{{ 'generic.searchByNamePlaceholder' | translate }}">
                    </mat-form-field>
                </div>
            </div>

            <!-- Tabella Angular Material -->
            <div class="mat-elevation-z8">
                <table mat-table [dataSource]="dataSource" matSort class="full-width-table">
                    <!-- Colonna: Titolo -->
                    <ng-container matColumnDef="title">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'userNotes.columns.title' | translate }} </th>
                        <td mat-cell *matCellDef="let element"> {{ element.title }} </td>
                    </ng-container>

                    <!-- Colonna: Testo -->
                    <ng-container matColumnDef="text">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'userNotes.columns.text' | translate }} </th>
                        <td mat-cell *matCellDef="let element"> {{ element.text }} </td>
                    </ng-container>

                    <!-- Colonna: Data di creazione -->
                    <ng-container matColumnDef="created_at">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'userNotes.columns.createdAt' | translate }} </th>
                        <td mat-cell *matCellDef="let element"> {{ element.created_at | date:'short' }} </td>
                    </ng-container>

                    <!-- Colonna: Azioni -->
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> {{ 'generic.actions' | translate }} </th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-icon-button color="accent" (click)="viewNote(element)">
                                <mat-icon>visibility</mat-icon>
                            </button>
                            <button mat-icon-button color="primary" (click)="editNote(element)">
                                <mat-icon>edit</mat-icon>
                            </button>
                            <button mat-icon-button color="warn" (click)="deleteNote(element)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </ng-container>


                    <!-- Header e righe della tabella -->
                    <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
                    <tr mat-row *matRowDef="let row; columns: columnsToDisplay;"></tr>
                </table>
                <!-- Paginatore -->
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" aria-label="Select page"></mat-paginator>
            </div>
        </div>
    </ng-container>
</div>

<!-- Bottone per tornare alla Home -->
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/home']" routerLinkActive="router-link-active">
    <mat-icon>arrow_back</mat-icon>
    {{ 'generic.buttons.home' | translate }}
  </button>
</div>
