<mat-toolbar color="primary">
  <mat-toolbar-row>
    <span>{{ 'generic.import-survey' | translate }}</span>
    <span class="modal-toolbar-spacer"></span>
    <button mat-icon-button class="ml-auto">
      <mat-icon (click)="closeDialogue()">close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="container">
  <app-all-surveys
    [projectId]="data?.project_id"
    [addable]="false"
    (surveySelected)="closeDialogue($event)"
    [surveySelectable]="true"
    [surveyViewable]="false"
  />
</div>
