import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router, RouterStateSnapshot} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {SurveyService} from '../../../services/surveys.service';
import {ToastService} from '../../../services/general/toast.service';
import {TranslateService} from '@ngx-translate/core';
import {MatStepper} from '@angular/material/stepper';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-survey-response-page',
  templateUrl: './survey-response-page.component.html',
  styleUrls: ['./survey-response-page.component.scss']
})
export class SurveyResponsePageComponent implements OnInit {
  @ViewChild('stepper', {static: false}) stepper: MatStepper;
  userInfoForm = this._formBuilder.group({
    firstCtrl: ['', Validators.required],
  });

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private _formBuilder: FormBuilder,
    private surveyService: SurveyService,
    public userService: UserService,
    private toast: ToastService,
    private translateService: TranslateService,
  ) {
  }

  loading = false;
  survey_id = null;
  survey = null;

  ngOnInit(): void {
    this.init();
  }

  init() {
    this.route.params.subscribe(params => {
      this.survey_id = params.survey_id;
      this.getSurvey();
    });
  }

  getSurvey() {
    this.loading = true;
    this.surveyService.surveyDetails(this.survey_id, (result) => {
      this.survey = result;
      if (result.visibility_type === "PRIVATE") {
        this.userService.getUser(() => {
          this.userService.resetCurrentLanguage();
        });
      }
      if (!this.userService.userInfo) {
        this.survey.user = {
          name: '',
          email: '',
        };
      }
      this.survey.edit_after_submit = !!this.survey.edit_after_submit;
      this.survey.attachments = result.attachment_files?.concat(result.attachment_pubmeds);
      this.loading = false;
    }, (error) => {
      this.router.navigate(['/']);
      this.loading = false;
    });
  }

  surveyResponseIsValid() {
    const status = this.surveyService.surveyAnswerIsValid(this.survey);
    // @ts-ignore
    if (!status?.valid) {
      // @ts-ignore
      this.toast.error(this.translateService.instant('generic.warning'), this.translateService.instant(status?.message));
      return false;
    }

    return true;
  }

  onSubmit() {
    if (this.surveyResponseIsValid()) {
      this.loading = true;
      this.surveyService.createSurveyAnswer(this.survey_id, this.survey, (result) => {
        this.stepper.next();
        this.survey.answered = true;
        this.toast.success(
          this.translateService.instant('generic.success'),
          this.translateService.instant('pages.surveys.response.submitted-successfully'),
        );
        this.loading = false;
      }, () => {
        this.loading = false;
      });
    }
  }
}
