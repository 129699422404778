<div class="container-fluid container-custo border-custom">
  <div class="container">
    <div class="row">
      <div class="col-6 col-md-4"
           *ngIf="userCanCreateOrganization">
        <div class="box-custom" [routerLink]="['../new']">
          <div class="icon-container"><i class="icon-Add"></i></div>
        </div>
        <h3 class="text-dark mt-2 text-center">
          <strong>{{'pages.organizations.organization-management.component.organization-create' | translate}}</strong>
        </h3>
      </div>
      <div class="col-6 col-md-4">
        <div class="box-custom" [routerLink]="['./show']">
          <div class="icon-container"><i class="icon-business"></i></div>
        </div>
        <h3 class="text-dark mt-2 text-center">
          <strong>{{'pages.organizations.organization-management.component.organization-view' | translate}}</strong>
        </h3>
      </div>
      <div class="col-6 col-md-4" [ngClass]="{'offset-lg-2': userCanCreateOrganization}">
        <div class="box-custom" [routerLink]="['./user']">
          <div class="icon-container"><i class="icon-Profile"></i></div>
        </div>
        <h3 class="text-dark mt-2 text-center"><strong>{{'generic.users' | translate}}</strong></h3>
      </div>
      <div class="col-6 col-md-4">
        <!-- <div class="box-custom" [routerLink]="['./role']">
          <div class="icon-container"><i class="icon-Flag"></i></div>
        </div>
        <h3 class="text-dark mt-2 text-center"><strong>{{'generic.roles.roles' | translate}}</strong></h3> -->
        <div class="box-custom" [routerLink]="['./documents']">
          <div class="icon-container">
            <fa-icon [icon]="faFile"></fa-icon>
          </div>
        </div>
        <h3 class="text-dark mt-2 text-center"><strong>{{'generic.documents' | translate}}</strong></h3>
      </div>
      <div class="col-6 col-md-4">
        <div class="box-custom" [routerLink]="['./email-templates']">
          <div class="icon-container">
            <fa-icon [icon]="faEnvelope"></fa-icon>
          </div>
        </div>
        <h3 class="text-dark mt-2 text-center"><strong>{{'generic.mail-template' | translate}}</strong></h3>
      </div>
      <div class="col-6 col-md-4">
        <div class="box-custom" [routerLink]="['./organization-charts']">
          <div class="icon-container"><i class="icon-group"></i></div>
        </div>
        <h3 class="text-dark mt-2 text-center"><strong>{{'generic.organization-charts' | translate}}</strong></h3>
      </div>
      <div class="col-6 col-md-4">
        <div class="box-custom" [routerLink]="['./project-properties']">
          <div class="icon-container"><i class="icon-settings"></i></div>
        </div>
        <h3 class="text-dark mt-2 text-center">
          <strong>{{'pages.organizations.organization-management.component.project-properties' | translate}}</strong>
        </h3>
      </div>
    </div>
  </div>
</div>
<div class="buttons-container container py-4">
  <button class="button-primary float-left" mat-button [routerLink]="['/home']" routerLinkActive="router-link-active">
    <i class="icon-Backward-arrow-small"></i>
    {{'generic.buttons.home' | translate}}
  </button>
</div>
