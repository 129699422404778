import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {OrganizationService} from "../../services/organization.service";
import {ConfigService} from "../../services/config.service";
import {MatTableDataSource} from "@angular/material/table";
import {FormControl, NgForm} from "@angular/forms";
import {UserTypeService} from "../../services/config/user-types.service";
import { UserService } from 'src/app/services/user.service';
import { ToastService } from 'src/app/services/general/toast.service';
import { TranslateService } from '@ngx-translate/core';
import { AiService } from '../../services/ai.service';

// import {proposeRevision, rewriteStatement, rewriteStatementBeforeAnswers } from './static-json/ai-prompt.js';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { Clipboard } from '@angular/cdk/clipboard';
import {CriteriaService} from '../../services/config/criteria.service';
import {ResponseTypesService} from '../../services/config/response-types.service';

@Component({
  selector: 'app-modal-dashboard-ai-explanation',
  templateUrl: './modal-dashboard-ai-explanation.component.html',
  styleUrls: ['./modal-dashboard-ai-explanation.component.scss']
})
export class ModalDashboardAiExplanationComponent implements OnInit {
  displayedColumns: string[] = [
    // 'name',
    'sentiment',
    'feedback',
    'truefalse',
    'confidence',
    // 'evidence',
    // 'attachment_events',
    'select'
  ];
  usersData: any = new MatTableDataSource([]);

  constructor(
    public dialogRef: MatDialogRef<ModalDashboardAiExplanationComponent>, @Inject(MAT_DIALOG_DATA) public data: any = {},
    private organizationService: OrganizationService, private configService: ConfigService,
    private userTypeService: UserTypeService, private userService: UserService,
    private toast: ToastService,
    private translateService: TranslateService,
    private aiService: AiService,
    private clipboard: Clipboard,
    private sanitizer: DomSanitizer,
    private criteriaService: CriteriaService,
    private responsesTypeService: ResponseTypesService,
  ) {
  }

  copySuccess: boolean = false;
  copySuccessMessage: string = 'Text copied successfully!';
  disabledCopyButton: boolean = true;

  loading: boolean = false;
  explanation: any;
  organizationId: string;

  ngOnInit(): void {
    this.explanation = JSON.parse(JSON.stringify(this.data)).response;
    this.disabledCopyButton = false;
  }

  copyText(text: string) {
    this.clipboard.copy(text);
    this.copySuccess = true;
    this.closeDialogue();

    // Clear the success message after 2 seconds
    setTimeout(() => {
      this.copySuccess = false;
    }, 2000);
  }

  closeDialogue(event: any = null) {
    this.dialogRef.close({event: event});
  }

}

