<!-- Header del dialog -->
<mat-toolbar color="primary" class="dialog-header">
    <mat-toolbar-row>
        <span class="dialog-title">{{ dialogTitle }}</span>
        <span class="dialog-spacer"></span>
        <button mat-icon-button class="ml-auto" (click)="!loading ? closeDialog() : null">
            <mat-icon>close</mat-icon>
        </button>
    </mat-toolbar-row>
</mat-toolbar>

<!-- Progress bar in caso di caricamento -->
<div class="dialog-loading" *ngIf="loading">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
</div>

<div class="dialog-body container">
    <form [formGroup]="noteForm" (ngSubmit)="onSubmit()">
        <mat-card class="dialog-card my-5">
            <mat-card-content>
                <!-- Campo: Title -->
                <div class="form-group" *ngIf="!readMode">
                    <mat-form-field appearance="fill" class="full-width">
                        <mat-label>{{ 'userNotes.form.title' | translate }}</mat-label>
                        <input
                                matInput
                                formControlName="title"
                                placeholder="{{ 'userNotes.form.titlePlaceholder' | translate }}"
                        >
                        <mat-error *ngIf="noteForm.get('title').hasError('required') && (noteForm.get('title').touched || noteForm.get('title').dirty)">
                            {{ 'userNotes.form.titleError' | translate }}
                        </mat-error>
                    </mat-form-field>
                </div>
                <!-- Campo: Text (Rich Text Editor con Quill) -->
                <div class="form-group">
                    <div id="quillEditor" style="height: 200px;"></div>
                    <mat-error *ngIf="noteForm.get('text').hasError('required') && (noteForm.get('text').touched || noteForm.get('text').dirty)">
                        {{ 'userNotes.form.textError' | translate }}
                    </mat-error>
                </div>
            </mat-card-content>
            <mat-card-actions align="end" class="dialog-actions" *ngIf="!readMode" >
                <button mat-button type="button" (click)="closeDialog()">
                    {{ 'generic.cancel' | translate }}
                </button>
                <!-- Mostra il pulsante Salva solo se NON siamo in read mode -->
                <button mat-raised-button color="primary" type="submit" [disabled]="noteForm.invalid || loading">
                    {{ 'generic.buttons.save' | translate }}
                </button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>
