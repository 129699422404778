import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ScriptLoaderService} from 'angular-google-charts';
import {ModalAddAttachmentComponent} from 'src/app/modals/modal-add-attachment/modal-add-attachment.component';
import {ModalReportAttachmentComponent} from 'src/app/modals/modal-report-attachment/modal-report-attachment.component';
import {AnalysisMethodsService} from 'src/app/services/config/analysis-methods.service';
import {CriteriaService} from 'src/app/services/config/criteria.service';
import {ResponseTypesService} from 'src/app/services/config/response-types.service';
import {SuggestionService} from 'src/app/services/config/suggestion.service';
import {UnitsService} from 'src/app/services/config/units.service';

import * as _ from 'lodash';
import {TranslateService} from '@ngx-translate/core';
import {AlertService} from 'src/app/services/general/alert.service';
import {DecisionService} from '../../../../services/decision.service';
import {Router} from '@angular/router';
import {ToastService} from '../../../../services/general/toast.service';
import {ModalProposalRenameComponent} from '../../../../modals/modal-proposal-rename/modal-proposal-rename.component';
import swal, {SweetAlertIcon} from 'sweetalert2';
import {UserService} from '../../../../services/user.service';
import {ModalShowEvidencesComponent} from '../../../../modals/modal-show-evidences/modal-show-evidences.component';

import { ModalMagicWriterComponent  } from 'src/app/modals/modal-magic-writer/modal-magic-writer.component';
import {forEach} from 'lodash';

@Component({
  selector: 'app-consensus-new-proposal',
  templateUrl: './consensus-new-proposal.component.html',
  styleUrls: ['./consensus-new-proposal.component.scss'],
})
export class ConsensusNewProposalComponent implements OnInit, OnChanges {
  @Input() proposal: any;
  @Input() framework: any;
  @Input() decision_table: any;
  @Input() showPrivateInfo: any;
  @Output() saveAndAddEvent = new EventEmitter();
  @Output() saveAndBackEvent = new EventEmitter();
  @Output() addNewEvidenceEvent = new EventEmitter();
  @Output() addAttachmentListEvent = new EventEmitter();

  isInEvalutationSession = false;
  anonymousProposalInfo = false;

  loadingGraphics = true;

  /** Il model che contiene tutti i dati visualizzati */
  currentProposal: any = {
    framework: {
      primary_target: {evaluation_criteria: null, title: '', type: 'primary'},
      secondary_targets: [],
      criteria: [],
    },
  };

  // model: any = {
  //   primary_target: [],
  //   secondary_targets: [],
  //   proposals: [],
  // };

  loading = false;

  currentOrganization = {};

  newProposalInEditing = false;
  initialProposal: object = null;

  displayedColumns: string[] = [
    'name',
    'sentiment',
    'feedback',
    'truefalse',
    'confidence',
    'evidence',
    'attachment_events',
  ];
  displayedColumnsEvidence: string[] = ['type', 'name', 'link'];

  chartOptionsContradiction = {
    height: 200,
    enableInteractivity: false,
    displayAnnotations: true,
    hAxis: {
      textPosition: 'none',
      maxValue: 100,
      gridlines: {
        count: 0,
        color: 'transparent',
        format: 'percent',
      },
    },
    animation: {
      startup: true,
    },
  };
  chartOptionsFeedback = {
    height: 200,
    pieHole: 0.4,
    enableInteractivity: false,
    pieSliceText: 'none',
    slices: {
      0: {color: '#00bc1d'},
      1: {color: '#ff0000'},
      2: {color: '#e5e3e3'},
    },
    animation: {
      startup: true,
    },
  };

  constructor(
    private loaderService: ScriptLoaderService,
    private criteriaService: CriteriaService,
    private unitService: UnitsService,
    private suggestionService: SuggestionService,
    private responsesTypeService: ResponseTypesService,
    public dialog: MatDialog,
    private translateService: TranslateService,
    private alert: AlertService,
    private decisionService: DecisionService,
    private router: Router,
    private toast: ToastService,
    private userService: UserService,
  ) {
  }

  ngOnInit(): void {
    //  inizializzo i componenti
    if (this.proposal && this.decision_table && this.framework) {
      this.currentProposal = JSON.parse(JSON.stringify(this.proposal));

      this.getProposalData();

      if (
        this.currentProposal.evaluation_session != undefined &&
        this.currentProposal.evaluation_session_id != undefined
      ) {
        this.isInEvalutationSession = true;
      }

      // @ts-ignore
      this.proposal.criteria.forEach((criterion) => {
        criterion.initialValue = criterion.value;
        criterion.originalValue = criterion.value;
      });
    }

    //  inizializzo il componente per i grafici
    this.loaderService.loadChartPackages('corechart').subscribe(() => {
      this.loadingGraphics = false;
    });

    const currentOrganitazionArr = this.userService.userInfo.organizations.filter((currentOrganization) => {
      return (currentOrganization.id === this.userService.userInfo.details.current_organization_id);
    });

    this.currentOrganization = currentOrganitazionArr[0];
  }

  ngOnChanges(changes: SimpleChanges) {
    this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
    this.showPrivateInfo = JSON.parse(JSON.stringify(this.showPrivateInfo))
  }

  // onClickModalMagicWriter(criterio: any) {
  //   // console.log(criterio);
  //   const dialogRef = this.dialog.open(ModalMagicWriterComponent, {
  //     panelClass: 'small-dialog',
  //     data: {
  //       criterio: criterio,
  //       organization_id: this.currentOrganization['id'],
  //     }
  //   });
  //
  //   dialogRef.afterClosed().subscribe(attachmentData => {
  //     if (attachmentData?.event && attachmentData?.event && attachmentData?.event?.event == 'added') {
  //       this.init();
  //     }
  //   });
  // }

  init() {
    if (this.loadingGraphics) {
      setTimeout(() => {
        this.init();
      }, 200);
      return;
    } else {
      this.prepareChartContradiction();
      this.prepareChartFeedback();
    }
  }

  criterionIsChanged(criterio: any) {
    return criterio.initialValue !== criterio.value;
  }

  saveCriterionValue(criterio: any) {
    criterio.initialValue = criterio.value;
    criterio.modifyEnabled = false;
    criterio.modified = true;
    this.proposal.criteria.forEach((criterion) => {
      if (criterion.id === criterio.id) {
        criterion.value = criterio.value;
        criterion.initialValue = criterio.value;
      }
    });
    this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
  }

  /** Recupera le informazioni della proposal */
  getProposalData() {
    this.init();
  }

  get proposalIsEdited() {
    let edited = false;
    // @ts-ignore
    this.proposal.criteria.forEach((criterion) => {
      if (criterion.originalValue !== criterion.initialValue) {
        edited = true;
      }
    });
    return edited;
  }

  removeAttachment(attachment, criterion) {
    this.proposal.criteria.forEach((criterionTmp) => {
      if (criterion.id === criterionTmp.id) {
        criterionTmp.attachment = criterionTmp?.attachment?.filter((item) => {
          if (attachment.type === 'PUBMED') {
            return item.PMID !== attachment.PMID;
          } else {
            return item.id !== attachment.id;
          }
        });
        criterionTmp.attachment_pubmed = criterionTmp?.attachment_pubmed?.filter((item) => item.PMID !== attachment.PMID);
        criterionTmp.attachment_files = criterionTmp?.attachment_files?.filter((item) => item.id !== attachment.id);
      }
    });
    this.currentProposal = JSON.parse(JSON.stringify(this.proposal));
  }

  proposalIsRevisioned(proposal) {
    return proposal.revisions.length > 0;
  }

  /**
   * Prepara i grafici da disegnare per il grado di certezza e contraddizione ma solo per i criteri qualitativi
   */
  prepareChartContradiction() {
    this.currentProposal.criteria.forEach((criterio) => {
      if (
        criterio.results &&
        this.criteriaService.criteriaIsQualitative(criterio.typology)
      ) {
        this.drawChartContraddition(criterio);
      }
    });
  }

  get loggedUserDetails() {
    return this.userService.userInfo;
  }

  /**
   * Prepara i grafici da disegnare per i feedback ricevuti ma solo per i criteri qualitativi
   */
  prepareChartFeedback() {
    this.currentProposal.criteria.forEach((criterio) => {
      if (
        criterio.response.length > 0 &&
        this.criteriaService.criteriaIsQualitative(criterio.typology)
      ) {
        criterio.response_agree_count = criterio.response.filter(
          (x) => x.consent_value == 'AGREE'
        ).length;
        criterio.response_disagree_count = criterio.response.filter(
          (x) => x.consent_value == 'DISAGREE'
        ).length;
        criterio.response_abstained_count = criterio.response.filter(
          (x) => x.consent_value == 'ABSTAINED'
        ).length;
        this.drawChartFeedback(criterio);
      }
    });
  }

  /** Disegna i grafici di contraddizione e certezza */
  drawChartContraddition(criterio: any) {
    const data = google.visualization.arrayToDataTable([
      ['Element', 'Density', {role: 'style'}],
      [
        this.translateService.instant('generic.certainty-degree'),
        {
          v: criterio.results.certainty_degree,
          f: criterio.results.certainty_degree + '%',
        },
        '#1b61a1',
      ],
      [
        this.translateService.instant('generic.contradiction-degree'),
        {
          v: criterio.results.contradiction_degree,
          f: criterio.results.contradiction_degree + '%',
        },
        '#ff0000',
      ],
    ]);

    const view = new google.visualization.DataView(data);
    view.setColumns([
      0,
      1,
      {
        calc: 'stringify',
        sourceColumn: 1,
        type: 'string',
        role: 'annotation',
      },
      2,
    ]);

    const chart = new google.visualization.BarChart(
      document.getElementById('chart_div_' + criterio.title)
    );
    chart.draw(view, this.chartOptionsContradiction);
  }

  /** Disegna i grafici di contraddizione e certezza */
  drawChartFeedback(criterio: any) {
    const data = google.visualization.arrayToDataTable([
      ['Element', 'Density'],
      [
        this.translateService.instant('generic.agree'),
        criterio.response_agree_count,
      ],
      [
        this.translateService.instant('generic.disagree'),
        criterio.response_disagree_count,
      ],
      [
        this.translateService.instant('generic.abstained'),
        criterio.response_abstained_count,
      ],
    ]);

    const view = new google.visualization.DataView(data);

    const chart = new google.visualization.PieChart(
      document.getElementById('chart_feedback_div_' + criterio.title)
    );
    chart.draw(view, this.chartOptionsFeedback);
  }

  /**
   * Aggiunta di una nuova evidenza
   * @param criterio
   * @param attachment_type
   */
  onClickAddNewEvidence(criterio: any, attachment_type: string) {
    this.saveCriterionValue(criterio);
    this.addNewEvidenceEvent.emit({
      criterio,
      attachment_type,
    });
  }

  showExpertEvideces(objectUser) {
    const dialogRef = this.dialog.open(ModalShowEvidencesComponent, {
      panelClass: 'medium-dialog',
      data: {
        userFullName: objectUser?.team_member?.name || objectUser?.team_member?.email,
        attachments: objectUser.attachment_expert_pubmed.concat(objectUser.attachment_expert_files)
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
    });
  }

  /**
   * Evento di apertura della lista di allegati cliccati dall'esperto
   */
  onClickAttachmentEventList(response: any) {
    this.addAttachmentListEvent.emit({
      criteria_id: response.criteria_id,
      team_member_id: response.team_member_id,
    });
  }

  /**
   * Evento di modifica di una descrizione di un criterio
   */
  onClickModifyDescription(criterio: any) {
    criterio.modifyEnabled = true;
    criterio.initialValue = criterio.value;
  }

  /**
   * Evento di cancellazione di una descrizione di un criterio
   * @param criterio
   */
  onClickCancelDescription(criterio: any) {
    criterio.value = criterio.initialValue;
    criterio.modifyEnabled = false;
    criterio.criterioModified = false;
    this.newProposalInEditing = false;
  }

  /**
   * Evento di modifica di un criterio
   * @param criterio
   */
  onChangeCriterioDescription(criterio: any) {
    if (criterio.value != criterio.initialValue) {
      criterio.criterioModified = true;
      this.newProposalInEditing = true;
    }
  }

  saveAndBack() {
    this.saveAndBackEvent.emit();
  }

  saveAndAdd() {

    //  controllo se sono state modificate dei criteri per salvare la nuova proposta
    if (this.proposalIsEdited && this.proposalIsValid()) {
      this.loading = true;
      this.decisionService.addNewRevision(
        this.decision_table.id,
        this.currentProposal,
        (result) => {
          this.loading = false;
          this.saveAndAddEvent.emit(result);
        },
        (error) => (this.loading = false)
      );
    } else {
      //  TODO_TRADUZIONI
      // this.alert.showAlert(
      //   'Attenzione',
      //   'La proposta sarà salvata come nuova'
      // );
    }
    // this.saveAndBackEvent.emit();
  }

  onClickSaveAs(title) {
    const dialogRef = this.dialog.open(ModalProposalRenameComponent, {
      panelClass: 'medium-dialog',
      data: {
        proposalTitle: title
      },
    });

    dialogRef.afterClosed().subscribe((event) => {
      if (event != null) {
        this.currentProposal.title = event;
        this.loading = true;
        this.decisionService.addNewRevision(
          this.decision_table.id,
          this.currentProposal,
          (result) => {
            this.loading = false;
            this.saveAndAddEvent.emit(result);
          },
          (error) => (this.loading = false)
        );
      }
    });
  }

  proposalIsValid() {
    let valid = true;
    this.currentProposal.criteria.forEach((criterion) => {
      if (criterion.value === '') {
        valid = false;
        this.toast.error('Butterfly Decisions', this.translateService.instant('components.proposals.criteria.is-empty'));
        return;
      }
    });
    return valid;
  }

  public deleteProposal() {
    if (this.proposal.evaluation_session_id == null) {
      swal.fire({
        title: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-title'
        ),
        text: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-text'
        ),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#9169db',
        confirmButtonText: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-confirm-button-text'
        ),
        cancelButtonText: this.translateService.instant(
          'components.decision-table.show-decision-table.proposal.delete-alert-cancel-button-text'
        ),
      }).then((result) => {
        if (result.isConfirmed) {
          this.loading = true;
          this.decisionService.deleteProposal(
            this.decision_table.id,
            this.proposal,
            (result: any) => {
              this.loading = false;
              this.toast.success(
                this.translateService.instant(
                  'components.decision-table.show-decision-table.proposal.success-delete-proposal'
                )
              );
              this.saveAndAddEvent.emit({is_deleted: true, proposal_id: this.currentProposal.id});
            },
            () => {
              this.loading = false;
              this.toast.error(
                this.translateService.instant(
                  'components.decision-table.show-decision-table.proposal.error-delete-proposal'
                )
              );
            }
          );
        }
      });
    }
  }

  isContestProposalAccepted(proposal) {
    return (proposal.is_contest_proposal && proposal.is_contest_proposal_accepted) || !proposal.is_contest_proposal;
  }

  updateProposal() {
    if (this.proposalIsValid()) {
      this.loading = true;
      this.decisionService.updateProposal(
        this.decision_table.id,
        this.currentProposal,
        (result) => {
          this.loading = false;
          this.saveAndBackEvent.emit(this.currentProposal);
          return;
        },
        (error) => (this.loading = false)
      );
    }
  }

  getCriteriaService() {
    return this.criteriaService;
  }

  getUnitsService() {
    return this.unitService;
  }

  getSuggestionService() {
    return this.suggestionService;
  }

  getResponsesTypeService() {
    return this.responsesTypeService;
  }

  // isGeopharma(){
  //   return this.currentOrganization['name'] === "Geopharma";
  // }

  isVirtualAssistantServiceEnabled() {
    // console.log('current organization',this.currentOrganization['id']);
    return this.currentOrganization['customs'].features.virtual_assistant;
  }

}
