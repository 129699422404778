import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';

import {AllFrameworksComponent} from './pages/frameworks/all-frameworks/all-frameworks.component';
import {FrameworkComponent} from './pages/frameworks/framework/framework.component';
import {DecisionTableComponent} from './pages/decision-tables/decision-table/decision-table.component';
import {AllDecisionTableComponent} from './pages/decision-tables/all-decision-table/all-decision-table.component';
import {
  ShowDecisionTableComponent
} from './components/decision-table/show-decision-table/show-decision-table.component';
import {
  DecisionTableDashboardComponent
} from './pages/decision-tables/decision-table-dashboard/decision-table-dashboard.component';
import {ResponsesComponent} from './pages/responses/responses.component';
import {ResponsesSummaryComponent} from './pages/responses/responses-summary/responses-summary.component';
import {ResponsesEvaluationComponent} from './pages/responses/responses-evaluation/responses-evaluation.component';
import {
  ResponsesWeightTargetsComponent
} from './pages/responses/responses-weight-targets/responses-weight-targets.component';
import {
  ResponsesWeightCriteriaComponent
} from './pages/responses/responses-weight-criteria/responses-weight-criteria.component';
import {HomeComponent} from './pages/home/home.component';

import {ProjectsComponent} from './pages/projects/all-projects/projects.component';

import {BootstrapComponent} from './pages/bootstrap/bootstrap.component';
import {AuthGuard} from './_guards/auth.guard';
import {
  ResponsesSuggestNewProposalComponent
} from './components/responses/responses-suggest-new-proposal/responses-suggest-new-proposal.component';
import {ResponsesThankYouComponent} from './pages/responses/responses-thank-you/responses-thank-you.component';
import {ResponsesWelcomeComponent} from './pages/responses/responses-welcome/responses-welcome.component';
import {ResponsesExplainComponent} from './pages/responses/responses-explain/responses-explain.component';
import {TaskManagementComponent} from './pages/task-management/task-management/task-management.component';
import {
  DecisionManagementPlanComponent
} from './pages/decision-management-plan/decision-management-plan/decision-management-plan.component';

import {
  OrganizationManagementComponent
} from './pages/organizations/organization-management/organization-management.component';
import {OrganizationsComponent} from './pages/organizations/organizations.component';
import {OrganizationComponent} from './pages/organizations/organization/organization.component';
import {
  AllDocumentsManagementComponent
} from './pages/organizations/all-documents-management/all-documents-management.component';
import {DefinitionRolesComponent} from './pages/definition-roles/definition-roles.component';
import {
  OrganizationAddRoleComponent
} from './pages/organizations/organization-add-role/organization-add-role.component';
import {OrganizationalFunctionsComponent} from './pages/organizational-functions/organizational-functions.component';

import {AllUsersManagementComponent} from './pages/user/all-users-management/all-users-management.component';
import {SingleUserManagementComponent} from './pages/user/single-user-management/single-user-management.component';
import {CurriculumComponent} from './pages/user/curriculum/curriculum.component';
import {UserProfileComponent} from './pages/user/user-profile/user-profile.component';
import {ErrorPageComponent} from './error-page/error-page.component';
import {RegisterComponent} from './register/register.component';
import {LoginComponent} from './pages/login/login.component';

//  TODO: questa parte non è mai stata completata da Francesco
import {
  DecisionTableClosingComponent
} from './pages/decision-tables/decision-table-closing/decision-table-closing.component';
import {OnboardingComponent} from './pages/onboarding/onboarding.component';
import {ChangePasswordComponent} from './pages/user/change-password/change-password.component';
import {
  NewContestProposalComponentComponent
} from './components/contests/new-contest-proposal-component/new-contest-proposal-component.component';
import {PermissionsGuard} from './_guards/permissions.guard';
import {
  TemplateMailManagementComponent
} from './pages/organizations/template-mail-management/template-mail-management.component';
import {
  OrganizationChartsManagementComponent
} from './pages/organizations/organization-charts-management/organization-charts-management.component';
import {GuestComponent} from './pages/guest/guest.component';
import {ProjectDetailComponent} from './pages/projects/project-detail/project-detail.component';
import {FeatureGuard} from './_guards/feature.guard';
import {SurveyDetailsPageComponent} from './pages/surveys/survey-details-page/survey-details-page.component';
import {SurveyResponsePageComponent} from './pages/surveys/survey-response-page/survey-response-page.component';

import {FileViewerComponent} from './components/fileviewer/file-viewer.component';
import {DecisionJoinRequestComponent} from "./pages/decision-join-request/decision-join-request.component";
import {
  ProjectPropertyListComponent
} from "./pages/project-properties/project-property-list/project-property-list.component";
import {UserNotesListComponent} from "./pages/user-notes/user-notes-list/user-notes-list.component";

const routes: Routes = [
  //
  // { path: '**', redirectTo: '', pathMatch: 'full' },

  // onboarding
  {
    path: 'onboarding',
    component: OnboardingComponent,
    data: {title: 'Inserisci i tuoi dati', isOnBoarding: true},
  },

  //  bootstrap
  {
    path: '',
    component: BootstrapComponent,
    data: {title: 'Loading...', isBootstrap: true},
  },

  // login
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [AuthGuard],
    data: {title: 'Accedi', isHome: true},
  },

  // register
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthGuard],
    data: {title: 'Registrati', isHome: true},
  },

  //  home page
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: {title: 'Take better, faster, safer decisions', isHome: true},
  },

  {
    path: 'projects',
    data: {title: 'Projects', feature: 'projects', redirect: '/decision/all'},
    canActivate: [AuthGuard, FeatureGuard],
    children: [
      {
        path: '',
        component: ProjectsComponent,
        data: {title: 'pages.home.component.check-decision-table'},
      },
      {
        path: ':project_id',
        data: {title: 'Project'},
        children: [
          {
            path: '',
            component: ProjectDetailComponent,
          },
          {
            path: 'decision',
            component: DecisionTableComponent,
            data: {title: 'routing.title.open-decision-table'},
          },
        ],
      },
    ],
  },

  {
    path: 'surveys',
    data: {title: 'Surveys'},
    children: [
      {
        path: ':survey_id',
        data: {title: 'Survey'},
        children: [
          {
            path: '',
            component: SurveyDetailsPageComponent,
            data: {title: 'Survey', feature: 'surveys', redirect: '/'},
            canActivate: [AuthGuard, FeatureGuard],
          },
          {
            path: 'response',
            data: {title: 'Answer to survey'},
            component: SurveyResponsePageComponent,
          },
        ],
      },
    ],
  },

  //  guest page
  {
    path: 'guest',
    component: GuestComponent,
    canActivate: [AuthGuard],
    data: {title: 'Take better, faster, safer decisions', isHome: true},
  },

  // error 404 page
  {
    path: 'error-page',
    component: ErrorPageComponent,
    canActivate: [AuthGuard],
    data: {title: 'Errore 404', isErrorPage: true},
  },

  // file viewer page
  {
    path: 'file-viewer/:document_id',
    component: FileViewerComponent,
    canActivate: [AuthGuard],
    data: {title: 'File Viewer'},
  },

  //  framework
  {
    path: 'framework',
    data: {title: ''},
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: FrameworkComponent,
        data: {title: 'routing.title.framework-new'},
      },
      {
        path: 'all',
        component: AllFrameworksComponent,
        data: {title: 'routing.title.framework-all'},
        canActivate: [AuthGuard],
        pathMatch: 'full',
      },
      {
        path: ':id_framework',
        component: FrameworkComponent,
        data: {title: 'routing.title.framework'},
      },
    ],
  },

  //  tavolo decisionale
  {
    path: 'decision',
    data: {
      title: '',
      feature: 'projects',
      redirect: '/projects',
      featureIsNot: true,
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DecisionTableComponent,
        data: {title: 'routing.title.decision-table'},
      },
      {
        path: 'all',
        component: AllDecisionTableComponent,
        data: {title: 'routing.title.decision-table-all'},
        pathMatch: 'full',
      },
      {
        path: ':id_decision',
        data: {title: 'routing.title.decision-table-show'},
        children: [
          {path: '', redirectTo: 'show', pathMatch: 'full'},
          {
            path: 'show',
            component: ShowDecisionTableComponent,
            data: {title: 'routing.title.decision-table-show'},
          },
          {
            path: 'dashboard',
            component: DecisionTableDashboardComponent,
            data: {title: 'routing.title.decision-table-dashboard'},
          },
          {
            path: 'closing',
            component: DecisionTableClosingComponent,
            data: {title: 'routing.title.decision-table-closing'},
          },
          {
            path: 'task-management',
            component: TaskManagementComponent,
            data: {title: 'routing.title.decision-table-task-management'},
          },

        ],
      },
    ],
  },

  {
    path: 'decision/:id_decision/join-request',
    component: DecisionJoinRequestComponent,
    data: {
      title: 'routing.title.decision-join-request'
    },
  },

  //  risposte
  {
    path: 'responses',
    data: {title: ''},
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: ResponsesComponent,
        data: {title: 'routing.title.responses'},
      },
      {
        path: ':id_evaluation_session',
        data: {title: 'routing.title.responses-expert'},
        children: [
          {path: '', redirectTo: 'welcome', pathMatch: 'full'},
          {
            path: 'welcome',
            component: ResponsesWelcomeComponent,
            data: {title: 'routing.title.responses-welcome'},
          },
          {
            path: 'explain',
            component: ResponsesExplainComponent,
            data: {title: 'routing.title.responses-explain'},
          },
          {
            path: 'summary',
            component: ResponsesSummaryComponent,
            data: {title: 'routing.title.responses-summary'},
          },
          {
            path: 'weight-target',
            component: ResponsesWeightTargetsComponent,
            data: {title: 'routing.title.responses-weight-target'},
          },
          {
            path: 'weight-criteria',
            component: ResponsesWeightCriteriaComponent,
            data: {title: 'routing.title.responses-weight-criteria'},
          },
          {
            path: 'responses-evaluation',
            component: ResponsesEvaluationComponent,
            data: {title: 'routing.title.responses-evaluation'},
          },
          {
            path: 'suggest-proposal',
            component: ResponsesSuggestNewProposalComponent,
            data: {title: 'routing.title.responses-suggest-proposal'},
          },
          {
            path: 'suggest-proposal/:id_revision_proposal',
            component: ResponsesSuggestNewProposalComponent,
            data: {title: 'routing.title.responses-suggest-proposal'},
          },
          {
            path: 'thank-you',
            component: ResponsesThankYouComponent,
            data: {title: 'routing.title.responses-thank-you'},
          },
        ],
      },
    ],
  },

  {
    path: 'contests',
    canActivate: [AuthGuard],
    data: {title: 'routing.title.responses-suggest-proposal'},
    children: [
      {
        path: 'propose/:token',
        component: NewContestProposalComponentComponent,
        data: {title: 'routing.title.responses-suggest-proposal'},
      },
    ],
  },

  // organizzazioni
  {
    path: 'organization',
    data: {title: ''},
    canActivate: [AuthGuard],
    children: [
      {
        path: 'all',
        component: OrganizationsComponent,
        canActivate: [AuthGuard],
        // data: { title: 'routing.title.organization-all' },
        data: {title: 'pages.organizations.component.your-organizations'},
        pathMatch: 'full',
      },
      {
        path: 'new',
        component: OrganizationComponent,
        canActivate: [AuthGuard],
        data: {title: 'routing.title.organization-new'},
        pathMatch: 'full',
      },
      {
        path: ':id_organization',
        data: {title: 'routing.title.organization'},
        children: [
          {
            path: '',
            component: OrganizationManagementComponent,
            canActivate: [AuthGuard],
            data: {title: 'routing.title.organization-manage'},
            pathMatch: 'full',
          },
          {
            path: 'show',
            component: OrganizationComponent,
            canActivate: [AuthGuard],
            data: {title: 'routing.title.organization-show'},
            pathMatch: 'full',
          },
          {
            path: 'project-properties',
            component: ProjectPropertyListComponent,
            canActivate: [AuthGuard],
            data: {title: 'routing.title.project-properties'},
            pathMatch: 'full',
          },
          {
            path: 'role',
            data: {title: 'routing.title.role'},
            children: [
              {
                path: '',
                component: DefinitionRolesComponent,
                canActivate: [AuthGuard],
                data: {title: 'routing.title.organization-role-all'},
              },
              {
                path: 'new',
                component: OrganizationAddRoleComponent,
                canActivate: [AuthGuard],
                data: {title: 'routing.title.organization-role-new'},
                pathMatch: 'full',
              },
              {
                path: ':id_role',
                data: {title: 'routing.title.role-edit'},
                children: [
                  {
                    path: '',
                    component: OrganizationAddRoleComponent,
                    canActivate: [AuthGuard],
                    data: {title: 'routing.title.role-edit'},
                  },
                ],
              },
            ],
          },
          {
            path: 'user',
            data: {title: 'routing.title.user'},
            children: [
              {
                path: '',
                component: AllUsersManagementComponent,
                canActivate: [AuthGuard],
                data: {title: 'routing.title.user'},
              },
              {
                path: 'new/:id',
                component: SingleUserManagementComponent,
                canActivate: [AuthGuard],
                data: {title: 'routing.title.user-new'},
                pathMatch: 'full',
              },
              {
                path: ':id_user_organization',
                data: {title: 'routing.title.user-show'},
                children: [
                  {
                    path: '',
                    component: SingleUserManagementComponent,
                    canActivate: [AuthGuard],
                    data: {title: 'routing.title.user-show'},
                  },
                  {
                    path: 'curriculum/:id_curriculum',
                    component: CurriculumComponent,
                    canActivate: [AuthGuard],
                    data: {title: 'routing.title.user-curriculum'},
                    pathMatch: 'full',
                  },
                ],
              },
            ],
          },
          {
            path: 'documents',
            data: {title: 'routing.title.documents'},
            children: [
              {
                path: '',
                component: AllDocumentsManagementComponent,
                canActivate: [AuthGuard],
                data: {title: 'routing.title.documents'},
              },
            ],
          },
          {
            path: 'email-templates',
            data: {title: 'routing.title.email-templates'},
            children: [
              {
                path: '',
                component: TemplateMailManagementComponent,
                canActivate: [AuthGuard],
                data: {title: 'routing.title.email-templates'},
              },
            ],
          },
          {
            path: 'organization-charts',
            data: {title: 'routing.title.organization-charts'},
            children: [
              {
                path: '',
                component: OrganizationChartsManagementComponent,
                canActivate: [AuthGuard],
                data: {title: 'routing.title.organization-charts'},
              },
            ],
          },
        ],
      },
    ],
  },

  // User Notes
  {
    path: 'user-notes',
    component: UserNotesListComponent,
    canActivate: [AuthGuard],
    data: {title: 'User Notes'},
  },

  //  TODO_MICHELE: organizzazioni da passare sia sotto la route organization che user con la differenziazione all'interno del component
  {
    path: 'organizational-functions',
    component: OrganizationalFunctionsComponent,
    canActivate: [AuthGuard],
    data: {title: 'Creare funzioni organizzative'},
  },
  {
    path: 'dmp',
    component: DecisionManagementPlanComponent,
    data: {title: 'Decision Management Plan'},
  },
  {
    path: 'profile',
    data: {title: 'Profilo'},
    children: [
      {
        path: '',
        component: UserProfileComponent,
        canActivate: [AuthGuard],
        data: {title: 'Profilo'},
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent,
        canActivate: [AuthGuard],
        data: {title: 'routing.title.change-password'},
        pathMatch: 'full',
      },
      {
        path: 'curriculum/:id_curriculum',
        component: CurriculumComponent,
        canActivate: [AuthGuard],
        data: {title: 'routing.title.user-curriculum'},
        pathMatch: 'full',
      },
    ],
  },
];

function addPermissionsGuardToRoutes(routes: any[]): any[] {
  return routes.map(route => {
    if (!route.children) {
      if (!route.redirectTo) {
        route.canActivate = route.canActivate || [];
        route.canActivate.push(PermissionsGuard);
      }
    }
    if (route.children) {
      route.children = addPermissionsGuardToRoutes(route.children);
    }
    return route;
  });
}

@NgModule({
  imports: [RouterModule.forRoot(addPermissionsGuardToRoutes(routes), {useHash: true})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
